/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { map } from 'lodash';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import styles from './speedDials.styles';
import { Card } from '../../components/Card';
import { Button, IconOnlyButton } from '../../components/Button';
import { Table } from '../../components/Table';
import { getDeviceStatus } from '../../helper/translationFunctions';
import { IRequestParams } from '../../components/types';
import {
  IDevice,
  IDevicesState,
  IGetDeviceResponse,
} from '../devices/devices.slice';
import { Typography } from '../../components/Typography';
import { StarGreyIcon, StarPrimaryIcon } from '../../assets/icons';
import DeviceModal from './components/deviceModal';

interface ISpeedDialsView extends WithStyles<typeof styles> {
  devices: IDevicesState;
  devicesList: IGetDeviceResponse;
  handleSpeeddial: (data: any) => void;
  handleSpeeddialJoinCall: (id: string) => void;
  handleGetDevices: ({ limit, offset, sortOrder }: IRequestParams) => void;
  handleEditDevice: (data: any) => void;
  handleViewmodalOpen: () => void;
  handleViewmodalClose: () => void;
}

const SpeedDialsView: React.FC<ISpeedDialsView> = ({
  classes,
  devices,
  devicesList,
  handleSpeeddial,
  handleSpeeddialJoinCall,
  handleGetDevices,
  handleEditDevice,
  handleViewmodalOpen,
  handleViewmodalClose,
}: ISpeedDialsView) => {
  const intl = useIntl();
  const theme = useTheme();

  const [clickedDevice, setClickedDevice] = useState<IDevice>();

  const isTabScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const speedDialColumns = [
    {
      name: 'id',
      label: intl.formatMessage({
        id: 'id',
      }),
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'deviceName',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: '51%', minWidth: '50px', maxWidth: '50px' },
        }),
      },
    },
    {
      name: 'rebootStatus',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        display: !isTabScreen,
        setCellProps: () => ({
          style: { width: '35%', minWidth: '50px', maxWidth: '50px' },
        }),
      },
    },
    {
      name: 'favourite',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: !isMobile ? 210 : 150 },
        }),
      },
    },
  ];

  const _handleSpeeddialButton = (e: React.MouseEvent, row: any) => {
    e.stopPropagation();
    const status = row?.rebootStatus;
    if (status === 'ready' || status === 'device_ready') {
      const data = {
        beginTime: new Date(),
        name: null,
        location: null,
        deviceId: row?._id,
      };
      handleSpeeddial(data);
    } else {
      const str1 = row?.commands?.meetingUrl;
      if (str1) {
        const str2 = str1.substring(0, str1.indexOf('?'));
        const meetingLocation = str2.substring(str2.lastIndexOf('/') + 1);

        handleSpeeddialJoinCall(meetingLocation);
      }
    }
  };

  const speedDialRowEvent = useCallback(() => {
    return map(devicesList.data, (row) => {
      const status = row?.rebootStatus;

      const getButtonData = () => {
        switch (status) {
          case 'device_call_ongoing':
          case 'ongoing': // Need to replace with the correct device status
            return {
              customStyles: clsx(classes.actionButton),
              color: 'primary',
              label: intl.formatMessage({
                id: 'join_call',
              }),
            };
          case 'device_ready':
          case 'ready':
            return {
              customStyles: clsx(classes.actionButton),
              color: 'secondary',
              label: intl.formatMessage({
                id: 'start_call',
              }),
            };
          default:
            return {
              customStyles: clsx(classes.actionButton),
              disabled: true,
              label: '',
            };
        }
      };

      return {
        ...row,
        rebootStatus: getDeviceStatus(intl, row.rebootStatus),
        favourite: (
          <div className={classes.actionWrapper}>
            {!isMobile && (
              <IconOnlyButton customStyles={classes.iconOnlyButton} disabled>
                {row.favourite ? (
                  <img src={StarPrimaryIcon} alt="star-primary-icon" />
                ) : (
                  <img src={StarGreyIcon} alt="star-grey-icon" />
                )}
              </IconOnlyButton>
            )}

            <Button
              id="speed-dial-table-action"
              variant="contained"
              size="small"
              onClick={(e) => _handleSpeeddialButton(e, row)}
              {...getButtonData()}
            />
          </div>
        ),
      };
    });
  }, [devices]);

  const handleRowOnClick = (data: any) => {
    const selectedDevice = devicesList.data.find(
      (device) => device.id === data[0]
    );

    setClickedDevice(selectedDevice);
    handleViewmodalOpen();
  };

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="users-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          <FormattedMessage id="speed_dials" />
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            id="speed-dial-table"
            title={
              <div id="speed_dials">
                <Typography variant="button">
                  {intl.formatMessage(
                    {
                      id: 'total_speed_dials',
                    },
                    {
                      count: devicesList?.total,
                    }
                  )}
                </Typography>
                {(devices.loading || devices.isSpeedDialDataLoading) && (
                  <span>
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: 'relative', top: 4 }}
                    />
                  </span>
                )}
              </div>
            }
            customStyles={classes.tableWrapper}
            columns={speedDialColumns}
            data={speedDialRowEvent()}
            pagination
            filter
            onRowClick={handleRowOnClick}
          />
        </Card>
      </div>
      <DeviceModal
        open={devices.openDeviceViewModal}
        handleClose={handleViewmodalClose}
        device={clickedDevice}
        handleEditDevice={handleEditDevice}
        loading={devices.loading || devices.isSpeedDialDataLoading}
      />
    </div>
  );
};

export default memo(withStyles(styles)(SpeedDialsView));

/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScheduledEventsView from './scheduledEvent.view';
import {
  readScheduledEventAsync,
  selectScheduledEvent,
  actions as scheduledEventActions,
  deleteScheduledEventAsync,
} from './scheduledEvent.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../auth/auth.slice';
import { generateMeetingUrl } from '../../helper/conference';
import { actions as appActions } from '../app/app.slice';
import { CreateScheduledEventModal } from './component/createScheduledEvent';
import { IRequestParams } from '../../components/types';

const ScheduledEventContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const scheduledEvent = useAppSelector(selectScheduledEvent);
  const auth = useAppSelector(selectAuth);
  const history = useHistory();

  const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);
  const [ongoingMeetings, setOngoingMeetings] = useState<Array<any>>([]);
  const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(appActions.setLocationCode('10123'));
  }, []);

  useEffect(() => {
    dispatch(readScheduledEventAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();

    const past = scheduledEvent?.list
      ?.filter(({ beginTime }) => beginTime <= now)
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const all = scheduledEvent?.list?.map((obj) => {
      let meetingType;
      if (obj.beginTime >= now) {
        meetingType = 'upcoming';
      } else if (obj.beginTime <= now && obj.endTime >= now) {
        meetingType = 'ongoing';
      } else {
        meetingType = 'past';
      }
      return {
        ...obj,
        meetingType,
      };
    });

    const ongoingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'ongoing' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));
    const upcomingMeetingsList = all
      ?.filter(
        (meeting) =>
          meeting.meetingType === 'upcoming' && meeting.type !== 'instant'
      )
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));

    setRecentMeetings(past);
    setUpcomingMeetings(upcomingMeetingsList);
    setOngoingMeetings(ongoingMeetingsList);
  }, [scheduledEvent.list]);

  const _scheduleMeetingOnClick = () => {
    dispatch(scheduledEventActions.openCreateScheduledEventModal());
  };

  const _joinToMeetingButtonClick = (location: string) => {
    const url = generateMeetingUrl(location, false);
    history.push(url);
  };

  const _handleSearch = (data: IRequestParams) => {
    dispatch(readScheduledEventAsync(data));
  };

  const _openUpdateScheduledEventModal = (data: any) => {
    dispatch(scheduledEventActions.openUpdateScheduledEventModal(data));
  };

  const _openViewScheduledEventModal = (data: any) => {
    dispatch(scheduledEventActions.openViewScheduledEventModal(data));
  };

  const _removeMeeting = (id: string) => {
    dispatch(deleteScheduledEventAsync(id));
  };

  return (
    <>
      <ScheduledEventsView
        upcomingMeetings={upcomingMeetings}
        ongoingMeetings={ongoingMeetings}
        recentMeetings={recentMeetings}
        permissions={auth.permissions}
        removeMeeting={_removeMeeting}
        joinToMeetingButtonClick={_joinToMeetingButtonClick}
        scheduleMeetingOnClick={_scheduleMeetingOnClick}
        handleSearch={_handleSearch}
        openUpdateScheduledEventModal={_openUpdateScheduledEventModal}
        openViewScheduledEventModal={_openViewScheduledEventModal}
      />

      <CreateScheduledEventModal />
    </>
  );
};

export default memo(ScheduledEventContainer);

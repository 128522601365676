import { createTheme, Theme as MaterialUITheme } from '@material-ui/core';

declare module '@material-ui/core' {
  interface Theme {
    custom: {
      primaryGradient: string;
      primaryGradientHover: string;
    };
  }
  interface ThemeOptions {
    custom: {
      primaryGradient: string;
      primaryGradientHover: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    common: {
      black: '#000000DE',
      white: '#FFFFFF',
    },
    // pink
    primary: {
      main: '#FF3B43',
      light: '#FE9CD5',
      dark: '#D02F36',
      contrastText: '#FFFFFF',
    },
    // blue
    secondary: {
      main: '#211A5B',
      light: '#9B94CD',
      dark: '#141037',
      contrastText: '#3F3977',
    },
    warning: {
      main: '#F79804DE',
      contrastText: '#fff',
    },
    info: {
      main: '#3B82F6',
      contrastText: '#707070',
    },
    error: {
      main: '#F44336',
      contrastText: '#707070',
    },
    success: {
      main: '#4CAF51',
      contrastText: '#707070',
    },
    grey: {
      100: '#F9F9F9',
      200: '#F2F2F2',
      300: '#F9F9F9',
      400: '#B5B5B5',
      500: '#B5B5B5',
      600: '#858585',
      700: '#686868',
      800: '#3C3C3C',
      900: '#000000',
    },
    text: {
      primary: '#211A5B',
    },
  },

  custom: {
    // This is using as background color gradient
    primaryGradient: 'linear-gradient(270deg, #FD0A90 0%, #FF3B43 100%)',
    primaryGradientHover: 'linear-gradient(270deg, #CA0677 0%, #D02F36 100%)',
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontSize: '36px',
      fontFamily: 'PT Sans',
      lineHeight: '48px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '36px',
      fontFamily: 'PT Sans',
      lineHeight: '48px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '24px',
      fontFamily: 'PT Sans',
      lineHeight: '48px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '24px',
      fontFamily: 'Open Sans',
      lineHeight: '36px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '16px',
      fontFamily: 'PT Sans',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '16px',
      fontFamily: 'PT Sans',
      lineHeight: '24px',
      fontWeight: 400,
    },
    caption: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    button: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    subtitle1: {
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    overline: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },
  },
});

export type Theme = MaterialUITheme;
export default theme;

import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    card: {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 5,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 5,
      },
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      justifyContent: 'space-between',
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
      overflow: 'auto',
    },
    buttonWrapper: {
      display: 'flex',
    },
    tableWrapper: {
      // position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
      '& .MuiTableBody-root .MuiTableRow-root': {
        cursor: 'pointer',
      },
    },
    actionWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
    actionButton: {
      '& .basic-button-class': {
        padding: '3px 22px',
      },
    },
    iconOnlyButton: {
      '& .custom-style-icon-button': {
        border: 'none',
        padding: 2,
        // color: 'yellow',
      },
    },
  });
};

export default styles;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  scheduleMeetingApi,
  editMeetingApi,
  instantMeetingApi,
  ICreateInstant,
  IScheduledMeetingData,
} from './scheduleMeeting.api';
import { fetchAllMeetingsAsync } from '../meetings/meetings.slice';
import { actions as appActions } from '../app/app.slice';
import { ApiError, IbaseDoc } from '../../components/types';
import { IUser, emptyUser } from '../auth/auth.slice';
import { IGroup, emptyGroup } from '../group/groups.slice';
import { IDevice } from '../devices/devices.slice';

export type Status = 'active' | 'deleted';

export interface IMeetingMember {
  userId: IUser;
  meetingId: string;
  status: Status;
}

export interface IMeetingDevice {
  deviceId: IDevice;
  meetingId: string;
  status: Status;
}

export interface ILiveStreaming {
  broadcatId: string;
  groupId: IGroup;
  streamId: string;
  streamKey: string;
}

export const emptyLiveStreaming: ILiveStreaming = {
  broadcatId: '',
  groupId: emptyGroup,
  streamId: '',
  streamKey: '',
};

export interface IScheduledEvent {
  viewingUrl: string;
}

export const emptyScheduledEvent: IScheduledEvent = {
  viewingUrl: '',
};

export interface IScheduleMeetings extends IbaseDoc {
  name: string;
  location: string;
  beginTime: string;
  endTime: string;
  host: IUser;
  groupId: IGroup;
  deviceId: string;
  type: string;
  participantCount: number;
  jwtToken: string;
  jwtTokenWithBaseUrl: string;
  deviceCronStatus: string;
  members: IMeetingMember[];
  devices: IMeetingDevice[];
  livestreamingdevices: IMeetingDevice[];
  livestreaming: ILiveStreaming;
  scheduledevent: IScheduledEvent;
  scheduledeventViewingUrl: string;
}

export interface IScheduleMeetingsState {
  data: IScheduleMeetings;
  meetingLocation: string;
  isScheduleModalOpen: boolean;
  isInstantMeetingModalOpen: boolean;
  isSpeeddialInprogress: boolean;
  isEditMeeting: boolean;
  loading: boolean;
  success: boolean;
  error: ApiError;
}

export const emptyScheduleMeeting: IScheduleMeetings = {
  _id: '',
  id: '',
  name: '',
  location: '',
  beginTime: new Date().toString(),
  endTime: new Date().toString(),
  host: emptyUser,
  groupId: emptyGroup,
  deviceId: '',
  type: '',
  participantCount: 0,
  jwtToken: '',
  jwtTokenWithBaseUrl: '',
  deviceCronStatus: '',
  members: [],
  devices: [],
  livestreamingdevices: [],
  livestreaming: emptyLiveStreaming,
  scheduledevent: emptyScheduledEvent,
  scheduledeventViewingUrl: '',
};

const initialState: IScheduleMeetingsState = {
  data: emptyScheduleMeeting,
  meetingLocation: '',
  isScheduleModalOpen: false,
  isInstantMeetingModalOpen: false,
  isSpeeddialInprogress: false,
  isEditMeeting: false,
  loading: false,
  success: false,
  error: null,
};

export const scheduleMeetingAsync = createAsyncThunk(
  'scheduleMeeting/createScheduleMeeting',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await scheduleMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_scheduled_meeting',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const instantMeetingAsync = createAsyncThunk(
  'scheduleMeeting/createInstantMeeting',
  async (data: ICreateInstant, { rejectWithValue, dispatch }) => {
    try {
      const response = await instantMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_instant_meeting',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editMeetingAsync = createAsyncThunk(
  'scheduleMeeting/edit',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await editMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_meeting',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const scheduleMeetingSlice = createSlice({
  name: 'scheduleMeeting',
  initialState,
  reducers: {
    openScheduleModal(state) {
      state.isScheduleModalOpen = true;
    },
    closeScheduleModal(state) {
      state.isScheduleModalOpen = false;
    },
    openSpeeddial(state) {
      state.isSpeeddialInprogress = true;
    },
    closeSpeeddial(state) {
      state.isSpeeddialInprogress = false;
    },
    openInstantMeetingModal(state) {
      state.isInstantMeetingModalOpen = true;
    },
    closeInstantMeetingModal(state) {
      state.isInstantMeetingModalOpen = false;
    },
    openEditMeetingModal(state, action) {
      state.isEditMeeting = true;
      state.isScheduleModalOpen = true;
      state.data = action.payload;
    },
    closeEditMeetingModal(state) {
      state.isEditMeeting = false;
      state.isScheduleModalOpen = false;
    },
    resetScheduleMeeting(state) {
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    setMeetingLocation(state, action) {
      state.meetingLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    // schedule a new meeting
    builder.addCase(scheduleMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(scheduleMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(scheduleMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // create a instant meeting
    builder.addCase(instantMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(instantMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(instantMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // edit meeting
    builder.addCase(editMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(editMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(editMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
  },
});

export const { actions } = scheduleMeetingSlice;

export const selectSchesuleMeeting = (
  state: RootState
): IScheduleMeetingsState => state.scheduleMeeting;

export default scheduleMeetingSlice.reducer;

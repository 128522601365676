/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useState } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  MoreHoriz,
  Share,
  DeleteOutline,
  VideocamOutlined,
  Edit,
} from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';

import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { Typography } from '../../components/Typography';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { ScheduleMeetingButtons } from '../scheduleMeeting';
import {
  ShareMeetingModal,
  DeleteMeetingModal,
  MeetingInfoModal,
} from './components';
import { emptyMeetingsViewIcon } from '../../assets/icons';
import styles from './meetings.styles';
import { Card } from '../../components/Card';
import { getMeetingName } from '../../helper/translationFunctions';

interface IMeetingsView extends WithStyles<typeof styles> {
  user: any;
  recentMeetings: Array<any>;
  upcomingMeetings: Array<any>;
  joinToMeetingButtonClick: (data: any) => void;
  removeMeeting: (id: string) => void;
  openEditMeetingModal: (data: any) => void;
}

const MeetingsView: React.FC<IMeetingsView> = ({
  classes,
  user,
  recentMeetings,
  upcomingMeetings,
  joinToMeetingButtonClick,
  removeMeeting,
  openEditMeetingModal,
}: IMeetingsView) => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [recentAnchor, setRecentAnchor] = useState<null | HTMLElement>(null);
  const [upcomingAnchor, setUpcomingAnchor] = useState<null | HTMLElement>(
    null
  );
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = useState(false);
  const intl = useIntl();

  const getMoreOptionList = (entry: string, data: any) => {
    const optionList = [
      {
        label: intl.formatMessage({
          id: 'share_invitation',
        }),
        id: 'meeting-share_invitation',
        onClick: () => setIsShareMeetingOpen(true),
        icon: <Share fontSize="small" />,
      },
      {
        label: intl.formatMessage({
          id: 'view',
        }),
        id: 'meeting-view',
        onClick: () => setIsMeetingInfoOpen(true),
        icon: <VideocamOutlined fontSize="small" />,
      },
    ];
    if (entry === 'edit') {
      optionList.push({
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'meeting-edit',
        onClick: () => openEditMeetingModal(data),
        icon: <Edit fontSize="small" />,
      });
    }
    if (entry === 'delete') {
      optionList.push({
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'meeting-delete',
        onClick: () => setIsDeleteMeetingOpen(true),
        icon: <DeleteOutline fontSize="small" />,
      });
    }
    if (entry === 'editDelete') {
      optionList.push({
        label: intl.formatMessage({
          id: 'edit',
        }),
        id: 'meeting-edit',
        onClick: () => openEditMeetingModal(data),
        icon: <Edit fontSize="small" />,
      });
      optionList.push({
        label: intl.formatMessage({
          id: 'delete',
        }),
        id: 'meeting-delete',
        onClick: () => setIsDeleteMeetingOpen(true),
        icon: <DeleteOutline fontSize="small" />,
      });
    }
    return optionList;
  };

  const [MeetingOptionList, setMeetingOptionList] = useState(
    getMoreOptionList('none', null)
  );

  const isMemberHosted = (host: any) => {
    if (user?.role === 'member' && host && host.id) {
      return host.id === user.id;
    }

    return false;
  };

  const handleClickRecentMtnActionBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) => {
    setSelectedMeeting(data);
    setRecentAnchor(e.currentTarget);
    if (user?.role === 'member' && data.type === 'schedule') {
      if (isMemberHosted(data?.host)) {
        setMeetingOptionList(getMoreOptionList('editDelete', data));
      } else {
        setMeetingOptionList(getMoreOptionList('none', data));
      }
    } else if (user?.role === 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else if (user?.role !== 'member' && data.type === 'schedule') {
      setMeetingOptionList(getMoreOptionList('editDelete', data));
    } else if (user?.role !== 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else {
      setMeetingOptionList(getMoreOptionList('none', data));
    }
  };

  const handleClickUpcomingMtnActionBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: any
  ) => {
    setSelectedMeeting(data);
    setUpcomingAnchor(e.currentTarget);
    if (user?.role === 'member' && data.type === 'schedule') {
      if (isMemberHosted(data?.host)) {
        setMeetingOptionList(getMoreOptionList('editDelete', data));
      } else {
        setMeetingOptionList(getMoreOptionList('none', data));
      }
    } else if (user?.role === 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else if (user?.role !== 'member' && data.type === 'schedule') {
      setMeetingOptionList(getMoreOptionList('editDelete', data));
    } else if (user?.role !== 'member' && data.type === 'instant') {
      setMeetingOptionList(getMoreOptionList('delete', data));
    } else {
      setMeetingOptionList(getMoreOptionList('none', data));
    }
  };

  const handleCloseRecentMtnActionBtn = () => {
    // setSelectedMeeting(null);
    setRecentAnchor(null);
  };

  const handleCloseUpcomingMtnActionBtn = () => {
    // setSelectedMeeting(null);
    setUpcomingAnchor(null);
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
  };

  const _handleCloseMeetingInfoModal = () => {
    setIsMeetingInfoOpen(false);
  };

  const _handleCloseDeleteMeetingModal = () => {
    setIsDeleteMeetingOpen(false);
  };

  const _recentMeetionActionButtonClick = (isExpired: boolean, data: any) => {
    if (isExpired) openEditMeetingModal(data);
    else joinToMeetingButtonClick(data);
  };

  // const recentMeetingActionList = [
  //   {
  //     label: 'Share Invitation',
  //     onClick: () => setIsShareMeetingOpen(true),
  //     icon: <Share fontSize="small" />,
  //   },
  //   {
  //     label: 'Delete Meeting',
  //     onClick: () => setIsDeleteMeetingOpen(true),
  //     icon: <DeleteOutline fontSize="small" />,
  //   },
  // ];

  const recentMtnTableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'beginTime',
      label: intl.formatMessage({
        id: 'time',
      }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          return (
            <div>
              <FormattedRelativeTime
                value={moment(value).unix() - moment.now() / 1000}
                numeric="auto"
                updateIntervalInSeconds={60}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const upcomingMtnTableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'host',
      label: intl.formatMessage({
        id: 'host',
      }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <div>{value?.email}</div>;
        },
      },
    },
    // {
    //   name: 'participantCount',
    //   label: intl.formatMessage({
    //     id: 'participant',
    //   }),
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
    //       return <div>{value}</div>;
    //     },
    //   },
    // },
    {
      name: 'beginTime',
      label: intl.formatMessage({
        id: 'time',
      }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <div>{moment(value).format('YYYY/MM/DD - hh:mm A')}</div>;
        },
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'more',
      label: intl.formatMessage({
        id: 'more',
      }),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const recentMtnRowEvent = useCallback(() => {
    return map(recentMeetings, (row) => {
      const isExpired = new Date() > new Date(row?.endTime);
      return {
        ...row,
        name: getMeetingName(intl, row),
        action: (
          <Button
            color={isExpired ? 'secondary' : 'primary'}
            id="meeting-table-rejoin"
            variant="text"
            label={
              !isExpired
                ? intl.formatMessage({
                    id: 'rejoin',
                  })
                : row.type === 'instant' || user?.role === 'member'
                ? ''
                : intl.formatMessage({
                    id: 'reschedule',
                  })
            }
            onClick={() => _recentMeetionActionButtonClick(isExpired, row)}
            customStyles={classes.tableActionButton}
          />
        ),
        more: (
          <IconButton
            id="meeting-table-more"
            size="small"
            onClick={(e) => handleClickRecentMtnActionBtn(e, row)}
          >
            <MoreHoriz color="primary" fontSize="inherit" />
          </IconButton>
        ),
      };
    });
  }, [recentMeetings]);

  const upcomingMtnRowEvent = useCallback(() => {
    return map(upcomingMeetings, (row) => {
      return {
        ...row,
        action: (
          <Button
            color="primary"
            id="recent-meeting-table-more"
            variant="text"
            label={intl.formatMessage({
              id: 'start',
            })}
            onClick={() => joinToMeetingButtonClick(row)}
            customStyles={classes.tableActionButton}
          />
        ),
        more: (
          <IconButton
            id="upcomming-meeting-table-more"
            size="small"
            onClick={(e) => handleClickUpcomingMtnActionBtn(e, row)}
          >
            <MoreHoriz color="primary" fontSize="inherit" />
          </IconButton>
        ),
      };
    });
  }, [upcomingMeetings]);

  const noMeetingsView = () => {
    return (
      <NoDataLayout
        image={emptyMeetingsViewIcon}
        heading={intl.formatMessage({
          id: 'dont_have_previous_meeting_to_show',
        })}
      >
        <ScheduleMeetingButtons />
      </NoDataLayout>
    );
  };

  if (upcomingMeetings.length === 0 && recentMeetings.length === 0)
    return noMeetingsView();

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="meetings-view">
        <Typography
          className={classes.heading}
          variant="h3"
          component="h2"
          color="textPrimary"
        >
          <FormattedMessage id="meetings" />
        </Typography>
        <div className={classes.buttonWrapper}>
          <ScheduleMeetingButtons />
        </div>
      </div>
      <div className={classes.bottomWrapper}>
        <Grid container className={classes.tableContainer}>
          <Grid item xs={12} sm={5} className={classes.gridItem}>
            <Card customStyles={classes.cardLeft}>
              <Table
                title={intl.formatMessage({
                  id: 'recent_meetings',
                })}
                id="recent-meeting"
                columns={recentMtnTableColumns}
                data={recentMtnRowEvent()}
                customStyles={classes.table}
                pagination
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.gridItem}>
            <Card customStyles={classes.cardRight}>
              <Table
                title={intl.formatMessage({
                  id: 'upcoming_meetings',
                })}
                id="upcoming-meeting"
                columns={upcomingMtnTableColumns}
                data={upcomingMtnRowEvent()}
                customStyles={classes.table}
                pagination
              />
            </Card>
          </Grid>
        </Grid>
      </div>
      <Dropdown
        id="recent-more-option-button"
        open={Boolean(selectedMeeting) && Boolean(recentAnchor)}
        anchorRef={recentAnchor}
        handleClose={handleCloseRecentMtnActionBtn}
        itemList={MeetingOptionList}
      />
      <Dropdown
        id="upcoming-more-option-button"
        open={Boolean(selectedMeeting) && Boolean(upcomingAnchor)}
        anchorRef={upcomingAnchor}
        handleClose={handleCloseUpcomingMtnActionBtn}
        itemList={MeetingOptionList}
      />
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={selectedMeeting}
        />
      )}
      {isMeetingInfoOpen && (
        <MeetingInfoModal
          open={isMeetingInfoOpen}
          handleClose={_handleCloseMeetingInfoModal}
          meeting={selectedMeeting}
        />
      )}
      {isDeleteMeetingOpen && (
        <DeleteMeetingModal
          open={isDeleteMeetingOpen}
          meeting={selectedMeeting}
          handleClose={_handleCloseDeleteMeetingModal}
          removeMeeting={removeMeeting}
        />
      )}
    </div>
  );
};

export default memo(withStyles(styles)(MeetingsView));

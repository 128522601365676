export const LOCALES = {
  ENGLISH: 'en-US',
  DANISH: 'da-DK',
};

export const messages = {
  [LOCALES.ENGLISH]: {
    hello: 'Hello',
    start_call: 'Start Call',
    schedule: 'Schedule',
    help: 'Help',
    no_upcoming_meeting: 'You have no upcoming meetings today',
    no_devices: 'No Videolink TVs',
    recent_meetings: 'Recent Meetings',
    upcoming_meetings: 'Upcoming Meetings',
    name: 'Name',
    time: 'Time',
    action: 'Action',
    more: 'More',
    host: 'Host',
    email: 'Email',
    participant: 'participant',
    meetings: 'Meetings',
    total_admins: 'Total administrators: {n, number}',
    admins: 'Administrators',
    total_customers: 'Total admin users: {n, number}',
    customers: 'Admin Users',
    contact_number: 'Contact Number',
    status: 'Status',
    parent: 'Parent',
    role: 'Role',
    total_members: 'Total Users: {n, number}',
    members: 'Users',
    description: 'Description',
    member_count: 'Group Users',
    total_groups: 'Total groups: {n, number}',
    groups: 'Groups',
    type: 'Type',
    tvavailable: 'TV Available',
    reboot_status: 'Videolink TV Status',
    total_devices: 'Total Videolink TVs: {n, number}',
    devices: 'Videolink TV',
    overview: 'Overview',
    speed_dials_tab: 'Speed Dials',
    instant_meeting: 'Start Call',
    schedule_meeting: 'Schedule',
    help_support: 'Help & Support',
    settings: 'Settings',
    sign_out: 'Sign Out',
    meeting_reports: 'Meeting Reports',
    reporting: 'Call History',
    admin: 'Administrator',
    host_meeting: 'Host A Meeting',
    enter_your_meeting_name: 'Enter your meeting name',
    instant_meeting_description: 'Start call and share with participants',
    add_meeting_title: 'Add meeting title',
    start_meeting: 'Start Meeting',
    meeting_name_required: 'Meeting Name is Required',
    schedule_meeting_description:
      'Share this meeting link with others to start your meeting',
    save_meeting: 'Save Meeting',
    start_time: 'Start Time',
    end_time: 'End Time',
    number_of_participants: 'Number of participants',
    enter_valid_date_time: 'Enter Valid Date/Time',
    end_time_cannot_be_before_begin_time:
      'End time cannot be before begin time',
    groupid_is_required: 'groupId is Required',
    reschedule: 'Reschedule',
    rejoin: 'Rejoin',
    share_invitation: 'Share Invitation',
    delete_meeting: 'Delete Meeting',
    share_meeting_description:
      'Your meeting has been successfully created. Share this meeting link with others to start your meeting. Copy link or share your Invitation with others',
    meeting_link: 'Meeting Link',
    external_meeting_link: 'External Meeting Link',
    copy_link: 'Copy link',
    room: 'Room',
    participants: 'Participants',
    done: 'Done',
    do_you_want_to_delete_this_meeting: 'Do you want to delete this Meeting?',
    cancel: 'Cancel',
    add_admin: 'Add Administrator',
    add_admin_description: 'Add administrator to manage Videolink',
    password: 'Password',
    please_insert_valid_password: 'Please insert a valid Password',
    please_insert_valid_email: 'Please insert a valid email address',
    please_insert_valid_contact_number: 'Please insert a valid contact number',
    please_insert_valid_description: 'Please insert a valid description',
    please_insert_valid_deviceid: 'Please insert a valid Videolink TV ID',
    please_insert_valid_device_name: 'Please insert a valid Videolink TV Name',
    please_insert_valid_name: 'Please insert a name',
    change_password: 'Change Password',
    change_password_description: 'Copy new password and send it to the user',
    new_password: 'New Password',
    copy_password_and_save: 'Copy Password and Save',
    make_customers_to_manage_meetings: 'Add admin user to manage meetings',
    active: 'active',
    pending: 'pending',
    promote_to_sub_admin: 'Promote to Admin Business User',
    demote_to_customer: 'Demote to Admin User',
    add_customer: 'Add Admin User',
    change_customer_password: 'Change admin user password',
    change_password_customer_description:
      'Copy new password and send it to the admin user',
    do_you_want_to_promote_this_customer:
      'Do you want to promote this admin user?',
    promote: 'Promote',
    demote: 'Demote',
    not_available: 'Not available',
    do_you_want_to_demote_this_subadmin:
      'Do you want to demote this admin business user?',
    do_you_want_to_delete_this_customer:
      'Do you want to delete this admin user?',
    add_member: 'Add Group Member',
    make_member_manage_meetings: 'Add group member to manage meetings',
    change_member_password: 'Change group member password',
    change_password_member_description:
      'Copy new password and send it to the group member',
    do_you_want_to_delete_this_member:
      'Do you want to delete this group member?',
    add_group: 'Add Group',
    edit_group: 'Edit Group',
    make_group_manage_meetings: 'Add group to manage meetings',
    select_at_least_one_member: 'Select at least one group member',
    edit_group_to_manage_meetings: 'Edit Group to manage meetings',
    do_you_want_to_delete_this_group: 'Do you want to delete this group?',
    add_device: 'Add Videolink TV',
    unassign_this_device: 'Unassign this Videolink TV',
    edit_this_device: 'Edit this Videolink TV',
    edit_device: 'Edit Videolink TV',
    edit_device_description: 'Edit this Videolink TV device information',
    add_device_to_manage_meetings: 'Add Videolink TV to manage meetings',
    device_id: 'Videolink TV ID',
    user_device_name: 'Videolink TV Name',
    do_you_want_to_delete_this_device:
      'Do you want to delete this Videolink TV?',
    dont_have_previous_meeting_to_show:
      'You do not have previous meetings to show',
    virtual_meetings_for_everyone: 'Virtual meetings for everyone',
    dashboard_description:
      'Best of all, your personal invitation link doesn’t expire and can be used anytime',
    back_to_home: 'Back to Home',
    join: 'Join',
    update_user_profile: 'Update User Profile',
    required_input_fields:
      'The Field Labels Marked With * Are Required Input Fields',
    locale: 'Language',
    profile_picture: 'Profile Picture',
    save: 'Save',
    current_password: 'Current Password',
    confirm_password: 'Confirm Password',
    update_password: 'Update Password',
    file_not_uploaded: 'File not uploaded',
    password_mismatch: 'Password mismatch',
    contact_info: 'Contact Info',
    google_map_api_key: 'Google Map API Key',
    support_mail: 'Support Mail',
    other: 'Other',
    social_media_links: 'Social Media Links',
    social_media_links_description:
      'Paste Your Social Media Profile Link With Appropriate Textfields',
    publish: 'Publish',
    title_required: 'Title is Required',
    company_name_required: 'Company Name is Required',
    meeting_endtext_required: 'Meeting End Text is Required',
    logo_required: 'Logo is Required',
    system_title: 'System title',
    company_name: 'Company Name',
    meeting_endpage_text: 'Meeting end page text',
    system_logo: 'System Logo',
    update_terms: 'Update Use Of Terms',
    update_privacy_policy: 'Update Privacy Policy',
    general_settings: 'General Settings',
    user_profile: 'User Profile',
    meeting_scheduled: 'Meeting Scheduled',
    waiting_host: 'Waiting for the host/participants',
    meeting_started: 'Meeting has started',
    meeting_expired: 'Meeting has expired, it looks like no one joined',
    meeting_concluded: 'Meeting concluded',
    meetings_list: 'Meetings list',
    started_time: 'Started Time',
    duration_minutes: 'Duration',
    all_hosts: 'All Hosts',
    search: 'Search',
    from: 'From',
    to: 'To',
    found_meeting_based_on_search: 'Found meetings based on your search',
    contact_us: 'Contact Us',
    contact_us_description:
      'We’d love to hear from you! Drop us a line and we promise to get back to you as soon as we can. Your thoughts and questions are important to us!',
    address: 'Address',
    privacy_policy: 'Privacy Policy',
    terms: 'Terms of use',
    continue: 'Continue',
    launch: 'Launch',
    delete: 'Delete',
    not_selected: 'Not Selected',
    start: 'Start',
    error_occurred: 'Error occurred',
    successfully_created_member: 'Successfully created group member',
    successfully_deleted_member: 'successfully deleted group member',
    successfully_reset_password: 'successfully reset password',
    successfully_updated_profile: 'successfully updated profile',
    successfully_updated_password: 'successfully updated password',
    successfully_created_customer: 'successfully created admin user',
    successfully_deleted_customer: 'successfully deleted admin user',
    successfully_promoted_customer: 'successfully promoted admin user',
    successfully_demoted_subadmin: 'successfully demoted admin business user',
    successfully_created_device: 'successfully created Videolink TV',
    successfully_unassigned_device: 'successfully unassigned Videolink TV',
    successfully_updated_device:
      'successfully updated Videolink TV information',
    successfully_updated_settings: 'successfully updated settings',
    successfully_created_group: 'successfully created group',
    successfully_deleted_group: 'successfully deleted group',
    successfully_updated_group: 'successfully updated group',
    successfully_removed_meeting: 'successfully removed meeting',
    successfully_created_scheduled_meeting:
      'successfully created scheduled meeting',
    successfully_created_instant_meeting: 'successfully created start call',
    successfully_edited_meeting: 'successfully edited meeting',
    successfully_updated_faq: 'successfully updated FAQ',
    successfully_deleted_faq: 'successfully deleted FAQ',
    successfully_uploaded_logo: 'successfully uploaded logo',
    successfully_uploaded_profile_picture:
      'successfully uploaded profile picture',
    successfully_created_user: 'successfully created administrator',
    successfully_deleted_user: 'successfully deleted administrator',
    waiting: 'Waiting',
    inprogress: 'In progress',
    expired: 'Expired',
    concluded: 'concluded',
    member: 'Group Member',
    customer: 'Admin User',
    user: 'User',
    subadmin: 'Admin Business User',
    admin2: 'Administrator',
    super_admin: 'super-admin',
    no_matching_record: 'Sorry, no matching records found',
    sort: 'Sort',
    sort_for: 'Sort for',
    next_page: 'Next Page',
    previous_page: 'Previous Page',
    rows_per_page: 'Rows per page:',
    of: 'of',
    download_csv: 'Download CSV',
    print: 'Print',
    view_columns: 'View Columns',
    filter_table: 'Filter Table',
    all: 'All',
    filters: 'FILTERS',
    reset: 'Reset',
    show_columns: 'Show Columns',
    show_hide_table_columns: 'Show/Hide Table Columns',
    rows_selected: 'row(s) selected',
    delete_selected_rows: 'Delete Selected Rows',
    created: 'Created',
    destroyed: 'Destroyed',
    duration: 'Duration',
    something_went_wrong: 'Something went wrong. Please try again later',
    email_password_cannot_empty: 'Email and password cannot be empty',
    credentials_did_not_match:
      'Credentials did not match those on record, Please retry or contact your administrator',
    profile_successfully_updated: 'Profile successfully updated',
    old_password_cannot_empty: 'Old password and password cannot be empty',
    current_password_incorrect: 'Current password entered is incorrect',
    password_length: 'Password must be at least 8 characters long',
    password_validation_failed: 'Password validation failed',
    no_files_uploaded: 'No files were uploaded',
    successfully_uploaded: 'Successfully uploaded',
    roomjid_mandatory: 'roomjid is mandatory',
    user_not_found: 'User not found',
    user_authorization_unsuccessful:
      'User authorization unsuccessful. Please try again',
    deviceid_cannot_empty: 'Videolink TV Id cannot be empty',
    user_already_assigned: 'A user already assigned to this Videolink TV',
    user_already_assigned2: 'User already assigned to a other Videolink TV',
    device_not_exists: 'A Videolink TV with that Id does not exists',
    name_cannot_empty: 'Name cannot be empty',
    group_already_exists: 'A group with that name already exists',
    user_with_userid_not_exists: 'A user with userId not exists',
    groupid_cannot_empty: 'Group Id cannot be empty',
    members_cannot_empty: 'Group members cannot be empty',
    group_with_groupid_not_exists: 'A group with that groupId not exists',
    meeting_name_mandatory: 'Meeting name is mandatory',
    end_time_cannot_before_begin_time: 'End time cannot be before begin time',
    meeting_not_found: 'Meeting not found',
    not_authorized_delete_meeting:
      'You are not authorized to delete this meeting',
    meeting_not_found_or_not_started:
      'Meeting not found or The meeting has not yet started, please try again later',
    settings_not_found: 'Settings not found',
    system_title_mandatory: 'System title is mandatory',
    company_name_mandatory: 'Company name is mandatory',
    meeting_end_page_text_mandatory: 'Meeting end page text is mandatory',
    logo_mandatory: 'Logo is mandatory',
    title_mandatory: 'Title is mandatory',
    answer_mandatory: 'Answer is mandatory',
    terms_mandatory: 'Terms is mandatory',
    help_mandatory: 'Help is mandatory',
    privacy_policy_mandatory: 'privacy Policy is mandatory',
    user_account_already_exists:
      'A user account with that email already exists',
    user_and_password_cannot_empty: 'User id and password cannot be empty',
    no_account_found: 'No account found with that id',
    not_authorized_change_password: 'Not authorized to change password',
    invalid_user_role: 'Invalid user role',
    userid_cannot_empty: 'User id cannot be empty',
    cannot_delete_yourself: 'You cannot delete yourself',
    not_authorized_delete_user: 'Not authorized to delete user',
    please_enter_valid_email_address: 'Please enter a valid email address',
    please_enter_valid_contact_number: 'Please enter a valid contactNumber',
    unverified_account:
      'This email has an unverified account with us. Please verify account & sign-in',
    verified_account:
      'This email has verified account with us. Please sign-in or reset credentials to continue',
    confirmation_email_sent:
      'Confirmation email has been sent successfully. Please check inbox to proceed',
    password_cannot_exceed: 'Password must not exceed 256 characters',
    passwords_not_match:
      'Passwords do not match. Please check and enter the same password',
    password_reset_successful:
      'Password reset successful. Sign in back to access your account',
    customer_not_found: 'Admin User not found',
    user_role_not_customer: 'User role is not admin user',
    customer_promotion_successful: 'Admin User promotion successful',
    subadmin_not_found: 'admin business user not found',
    user_role_not_subadmin: 'User role is not admin business user',
    subadmin_demotion_successful: 'admin business user demotion successful',
    dont_have_enough_permission:
      'You dont have enough permission to perform this action',
    minutes: 'minutes',
    management: 'Management',
    owner: 'Owner',
    edit_member: 'Edit Group Member',
    successfully_edited_member: 'successfully edited group member',
    successfully_edited_customer: 'successfully edited admin user',
    required_parmeters_cannot_empty: 'required parameters can not empty',
    email_already_exists: 'email already exists',
    view: 'View',
    edit: 'Edit',
    edit_admin: 'Edit Administrator',
    suspend: 'Suspend',
    suspend_this_user: 'Do you want to suspend this administrator?',
    unsuspend: 'Unsuspend',
    unsuspend_this_user: 'Do you want to unsuspend this administrator?',
    successfully_suspended_user: 'successfully suspended user',
    successfully_unsuspended_user: 'successfully unsuspended user',
    cannot_suspend_yourself: 'You cannot suspend yourself',
    not_authorized_suspend_user: 'Not authorized to suspend user',
    reports: 'Reports',
    edit_customer: 'Edit Admin User',
    edit_subadmin: 'Edit Admin Business User',
    view_subadmin: 'View Admin Business User',
    suspend_simple: 'suspend',
    account_supended: 'user account suspended',
    unsuspend_this_customer: 'Do you want to unsuspend this admin user?',
    unsuspend_this_subadmin:
      'Do you want to unsuspend this admin business user?',
    suspend_this_customer: 'Do you want to suspend this admin user?',
    suspend_this_subadmin: 'Do you want to suspend this admin business user?',
    successfully_suspended_customer: 'successfully suspended admin user',
    successfully_unsuspended_customer: 'successfully unsuspended admin user',
    group: 'Group',
    device_is_required: 'Videolink TV is required',
    device: 'Videolink TV',
    speed_dials: 'Speed Dials',
    member_will_be_deleted: 'group member will be deleted',
    device_will_be_unassigned: 'Videolink TV will be unassigned',
    group_will_be_deleted: 'group will be deleted',
    customer_will_be_deleted: 'admin user will be deleted',
    do_you_want_to_delete_this_subadmin:
      'Do you want to delete this admin business user?',
    revoke_system_access: 'Revoke system access',
    gain_system_access: 'Gain system access',
    delete_customer_record: 'Delete administrator user record',
    type_the_word: 'Type the word',
    delete_user: 'Delete user',
    are_you_sure_you_want_to_delete:
      'Are you sure you want to delete {name} from the system? Type "{action}" to confirm',
    are_your_sure_you_want_to_revoke_unsuspend:
      'Are you sure you want to {action} {name} from the system?. Type "{action}" to confirm',
    revoke: 'Revoke',
    group_management: 'Group management',
    update: 'Update',
    add: 'Add',
    group_name: 'Group name',
    create_group: 'Create group',
    deactive: 'Deactive',
    id: 'id',
    details: 'Details',
    remove: 'Remove',
    are_you_sure_you_want_to_remove: 'Are you sure you want to remove {name}?',
    device_not_authorized_delete:
      'Not authorized to delete Videolink TV (Not owning)',
    group_not_authorized_delete: 'Not authorized to delete group (Not owning)',
    group_not_authorized_update: 'Not authorized to update group (Not owning)',
    group_memberid_cannot_empty: 'Group User Id cannot be empty',
    group_member_not_exists: 'Group user not exists',
    group_member_not_authorized_delete: 'Group user not authorized to delete',
    managed_by: 'Managed By',
    join_call: 'Join call',
    invalid_role: 'Invalid Role',
    awaiting_user_confirmation: 'Awaiting user confirmation',
    privacy_timeout: 'Privacy Timeout',
    please_insert_valid_privacy_timeout:
      'Please insert minimum 15 sec as a valid Privacy Timeout',
    device_not_authorized_reset:
      'Not authorized to reset Videolink TV (Not owning)',
    do_you_want_to_reset_this_device: 'Do you want to reset this Videolink TV?',
    privacy_enabled: 'Privacy Enabled',
    device_with_deviceid_not_exists:
      'A Videolink TV with Videolink TV Id not exists',
    select_at_least_one_device: 'Select at least one Videolink TV',
    devices_count: 'Videolink TV',
    device_powered_off: 'PoweredOff',
    device_rebooting: 'Rebooting',
    device_call_ongoing: 'Call Ongoing',
    device_ready: 'Ready',
    true: 'true',
    false: 'false',
    speed_dial: 'Speed dial',
    email_not_found: 'Email address not found in our system',
    password_reset_email_sent:
      'Password reset email has been sent to your mail successfully. It will be valid for next 60 minutes',
    invalid_data: 'Invalid data',
    email_sent_successfully: 'Email sent to the user successfully',
    sign_in: 'Sign in',
    sign_in_to: 'Sign in to {workspace}',
    forgot_password: 'Forgot password',
    ok: 'Ok',
    forgot_password_description:
      'Please enter your registered email to identify your account to reset your password',
    forgot_password_email_sent: 'Password reset email sent',
    forgot_password_email_sent_description:
      'Password reset email has been sent to your mail successfully. Please check your emails',
    welcome_to_videolink: 'Welcome to Videolink',
    please_set_new_password: 'Please set a new password to proceed',
    proceed: 'Proceed',
    please_retry: 'Please retry',
    reset_link_expired:
      'Password reset link expired - Please create a new password reset',
    invitation_expired: 'Invitation expired - Please request a new invite',
    please_insert_valid_url: 'Please insert a valid url',
    url: 'URL',
    social_media: 'Social Media',
    default_system_locale: 'Default System Language',
    locale_mandatory: 'Language Mandatory',
    locale_required: 'Language Required',
    send_message: 'Send Message',
    write_your_message: 'Write your message in here',
    enter_your_name: 'Enter your name here',
    enter_your_email: 'Enter your email here',
    enter_your_contact_number: 'Enter your contact number here',
    please_insert_valid_message: 'Please insert valid message',
    successfully_sent_contactus: 'Successfully sent message',
    do_you_want_to_delete_these_groups: 'Do you want to delete these groups?',
    unauthorized: 'Unauthorized',
    forbidden: 'Forbidden',
    not_found: 'Not found',
    select_a_group: 'Select a group',
    you_have: 'You have',
    today: 'today',
    total_meet_hours: 'Total meet hours :',
    users_in_meetings: 'users in meetings',
    ongoing_meetings: 'Ongoing meetings',
    recent: 'Recent',
    ongoing: 'Ongoing',
    upcoming: 'Upcoming',
    meeting_id: 'Meeting ID',
    view_details: 'View details',
    live_streamings: 'Live Streamings',
    participant_count: 'Participant Count',
    new: 'new',
    recent_livestreaming: 'Recent Streamings',
    upcoming_livestreaming: 'Upcoming Streamings',
    ongoing_livestreaming: 'Ongoing Streamings',
    create_stream: 'Create Live Streaming',
    create_stream_description:
      'Start streaming live to engage your community instantly.',
    create: 'Create',
    title: 'Title',
    date: 'date',
    delete_stream: 'Delete Live Streaming',
    delete_stream_description:
      'This action cannot be undone! Are you sure you want to delete this “{name}” Stream?',
    no_title: 'No title',
    successfully_created_livestreaming: 'successfully created livestreaming',
    successfully_edited_livestreaming: 'successfully edited livestreaming',
    successfully_deleted_livestreaming: 'successfully deleted livestreaming',
    are_you_sure: 'Are you sure?',
    youtube_disconnect_description:
      'Confirm to disconnect YouTube link and stop all related streaming services. You can reconnect anytime.',
    youtube_connectivity: 'Youtube Connectivity',
    update_stream: 'Edit Live Streaming Details',
    view_stream: 'Live Streaming Details',
    view_stream_description:
      'View all important details of your live streaming sessions.',
    create_streaming_viewers_note:
      '"Streaming Viewers" are the actual viewers who are viewing the live streaming event.',
    meeting_participants: 'Meeting Participants',
    streaming_viewers: 'Streaming Viewers',
    filter: 'Filter',
    dense: 'Dense',
    dense_padding: 'Dense padding',
    select: 'Select',
    streamings: 'Streamings',
    scheduled_events: 'Scheduled Events',
    recent_scheduled_events: 'Recent Scheduled Events',
    upcoming_scheduled_events: 'Upcoming Scheduled Events',
    ongoing_scheduled_events: 'Ongoing Scheduled Events',
    recent_events: 'Recent Events',
    upcoming_events: 'Upcoming Events',
    ongoing_events: 'Ongoing Events',
    create_scheduled_event: 'Create Scheduled Event',
    update_scheduled_event: 'Edit Scheduled Event',
    view_scheduled_event: 'View Scheduled Event',
    streaming_url: 'Streaming URL',
    create_scheduled_event_description:
      'Schedule an event to engage your community instantly.',
    view_scheduled_event_description:
      'View all important details of your scheduled event.',
    enter_valid_url: 'Please enter a valid URL.',
    successfully_created_scheduled_event: 'successfully scheduled an event',
    successfully_edited_scheduled_event: 'successfully edited scheduled event',
    successfully_deleted_scheduled_event:
      'successfully deleted scheduled event',
    view_more: 'View More',
    total_meetings: 'Total Meetings: {count}',
    view_meeting: 'View Meeting',
    view_meeting_description: 'View the meeting details.',
    count_participants: '{count} participants',
    videolink_tv_count: 'Videolink TV count',
    total_speed_dials: 'Total Speed Dials : {count}',
    view_speed_dial: 'View Speed Dial',
    view_speed_dial_description: 'View details of your Videolink TV.',
    videolink_tv: 'Videolink TV',
    deleted: 'deleted',
    meeting_name: 'Meeting Name',
    scheduledevent_cannot_empty: 'scheduled event cannot empty',
    scheduledevent_not_exists: 'scheduled event not exists',
    scheduledevent_not_authorized_stop:
      'scheduled event not authorized to stop',
  },
  [LOCALES.DANISH]: {
    hello: 'Velkommen',
    start_call: 'Start opkald',
    schedule: 'Planlæg',
    help: 'Hjælp',
    no_upcoming_meeting: 'Du har ingen kommende opkald i dag',
    no_devices: 'Ingen Videolink TV',
    recent_meetings: 'Seneste opkald',
    upcoming_meetings: 'Kommende opkald',
    name: 'Navn',
    time: 'Tid',
    action: 'Handling',
    more: 'Mere',
    host: 'Vært',
    email: 'E-mail',
    participant: 'Deltager',
    meetings: 'Opkald',
    total_admins: 'Samlet administratorer: {n, number}',
    admins: 'Administratorer',
    total_customers: 'Samlede Bruger administrator: {n, number}',
    customers: 'Bruger administrator',
    contact_number: 'Kontakt nummer',
    status: 'Status',
    parent: 'Forælder',
    role: 'Rolle',
    total_members: 'Samlet antal brugere: {n, number}',
    members: 'Brugere',
    description: 'Beskrivelse',
    member_count: 'Antal brugere',
    total_groups: 'Samlet antal grupper: {n, number}',
    groups: 'Grupper',
    type: 'Skriv',
    tvavailable: 'TV til rådighed',
    reboot_status: 'Videolink TV status',
    total_devices: 'Samlet antal Videolink TV: {n, number}',
    devices: 'Videolink TV',
    overview: 'Oversigt',
    speed_dials_tab: 'Straks Opkald',
    instant_meeting: 'Start opkald',
    schedule_meeting: 'Planlæg et opkald',
    help_support: 'Hjælp & Support',
    settings: 'Indstillinger',
    sign_out: 'Log ud',
    meeting_reports: 'Opkalds rapporter',
    reporting: 'Rapporter',
    admin: 'Administrator',
    host_meeting: 'Vær vært for et opkald',
    enter_your_meeting_name: 'Indtast dit opkalds nvn',
    instant_meeting_description: 'Start et opkald og del med deltagerne',
    add_meeting_title: 'Tilføj opkaldstitel',
    start_meeting: 'Start opkald',
    meeting_name_required: 'Opkaldsnavn er påkrævet',
    schedule_meeting_description:
      'Del dette opkaldslink med andre for at starte dit opkald',
    save_meeting: 'Gem opkald',
    start_time: 'Starttidspunkt',
    end_time: 'Sluttid',
    number_of_participants: 'Antal deltagere',
    enter_valid_date_time: 'Indtast gyldig dato/klokkeslæt',
    end_time_cannot_be_before_begin_time:
      'Sluttidspunktet kan ikke være før starttidspunktet',
    groupid_is_required: 'groupId er påkrævet',
    reschedule: 'Flyt opkald',
    rejoin: 'Tilslut igen',
    share_invitation: 'Del invitation',
    delete_meeting: 'Slet opkald',
    share_meeting_description:
      'Dit opkald er blevet oprettet. Del dette opkaldslink med andre for at starte dit opkald. Kopiér link eller del din invitation med andre',
    meeting_link: 'Opkaldslink',
    external_meeting_link: 'Eksternt opkaldslink',
    copy_link: 'Kopier link',
    room: 'Værelse',
    participants: 'Deltagere',
    done: 'Færdig',
    do_you_want_to_delete_this_meeting: 'Vil du slette dette opkald?',
    cancel: 'Fortryd',
    add_admin: 'Tilføj Administrator',
    add_admin_description: 'Tilføj administrator for at administrere opkald',
    password: 'Adgangskode',
    please_insert_valid_password: 'Indsæt venligst en gyldig adgangskode',
    please_insert_valid_email: 'Indsæt venligst en gyldig e-mail',
    please_insert_valid_contact_number:
      'Venligst indsæt korrekt kontakt nummer',
    please_insert_valid_description: 'Venligst indsæt gyldig beskrivelse',
    please_insert_valid_deviceid: 'Venligst indsæt gyldigt Videolink TV ID',
    please_insert_valid_device_name:
      'Venligst indsæt gyldigt Videolink TV-navn',
    please_insert_valid_name: 'Venligst indsæt gyldigt navn',
    change_password: 'Skift adgangskode',
    change_password_description:
      'Kopier ny adgangskode og send den til brugeren',
    new_password: 'Ny adgangskode',
    copy_password_and_save: 'Kopier adgangskode og gem',
    make_customers_to_manage_meetings:
      'Tilføj Bruger Administrator for at administrere opkald',
    active: 'Aktiv',
    pending: 'Afventer',
    promote_to_sub_admin: 'Forfremme til Forretnings Bruger Administrator',
    demote_to_customer: 'Nedgraderes til Bruger Administrator',
    add_customer: 'Tilføj Bruger Administrator',
    change_customer_password: 'Ændre Bruger Administrator adgangskode',
    change_password_customer_description:
      'Kopier ny adgangskode og send den til Bruger Administrator',
    do_you_want_to_promote_this_customer:
      'Ønsker du at ugradere denne Bruger Administrator',
    promote: 'Forfremme',
    demote: 'Degradere',
    not_available: 'Ikke tilgængelig',
    do_you_want_to_demote_this_subadmin:
      'Ønsker du at nedgradere denne Forretnings Bruger Administrator',
    do_you_want_to_delete_this_customer:
      'Ønsker du at slette denne Bruger Administrator',
    add_member: 'Tilføj gruppemedlem',
    make_member_manage_meetings:
      'Tilføj gruppemedlem til at administrere møder',
    change_member_password: 'Skift adgangskode til gruppemedlem',
    change_password_member_description:
      'Kopiér ny adgangskode og send den til gruppemedlemmet',
    do_you_want_to_delete_this_member: 'Vil du slette dette gruppemedlem',
    add_group: 'Tilføj gruppe',
    edit_group: 'Ret gruppe',
    make_group_manage_meetings: 'Få gruppen til at foretage opkald',
    select_at_least_one_member: 'Vælg mindst ét ​​gruppemedlem',
    edit_group_to_manage_meetings: 'Rediger gruppe for at foretage opkald',
    do_you_want_to_delete_this_group: 'Vil du slette denne gruppe',
    add_device: 'Tilføj Videolink TV',
    unassign_this_device: 'Fjern tildelingen af denne Videolink TV',
    edit_this_device: 'Rediger dette Videolink TV',
    edit_device: 'Rediger Videolink TV',
    edit_device_description: 'Rediger denne Videolink TV-enhedsoplysninger',
    add_device_to_manage_meetings:
      'Tilføj Videolink TV for at kunne foretage opkald',
    device_id: 'Videolink TV ID',
    user_device_name: 'Videolink TV-navn',
    do_you_want_to_delete_this_device:
      'Ønsker du at slette denne Videolink TV?',
    dont_have_previous_meeting_to_show: 'Du har ingen tidligere opkald at vise',
    virtual_meetings_for_everyone: 'Virtuelle opkald for alle',
    dashboard_description: 'Oversigts beskrivelse',
    back_to_home: 'Tilbage til oversigt',
    join: 'Deltag i opkald',
    update_user_profile: 'Opdater brugerprofil',
    required_input_fields: 'Nødvendige indtastningsfelter',
    locale: 'Lokal',
    profile_picture: 'Profil billede',
    save: 'Gem',
    current_password: 'Nuværende adgangskode',
    confirm_password: 'Bekræft adgangskode',
    update_password: 'Opdater adgangskode',
    file_not_uploaded: 'Fil er ikke uploadet',
    password_mismatch: 'Adgangskode er ikke korrekt',
    contact_info: 'Kontaktoplysninger',
    google_map_api_key: 'Googlekort API nøgle',
    support_mail: 'Support e-mailadresse',
    other: 'Andre',
    social_media_links: 'Links til sociale medier',
    social_media_links_description: 'Links forklaring af sociale medier',
    publish: 'Offentliggøre',
    title_required: 'Titel påkrævet',
    company_name_required: 'Firmanavn påkrævet',
    meeting_endtext_required: 'Opkald sluttekst påkrævet',
    logo_required: 'Logo påkrævet',
    system_title: 'System titel',
    company_name: 'Firma navn',
    meeting_endpage_text: 'Opkalds slutside tekst',
    system_logo: 'System logo',
    update_terms: 'Opdater betingelser',
    update_privacy_policy: 'Opdater fortrolighedspolitik',
    general_settings: 'Generelle indstillinger',
    user_profile: 'Bruger profil',
    meeting_scheduled: 'Opkald planlagt',
    waiting_host: 'Venter på opkalds vært',
    meeting_started: 'Opkald started',
    meeting_expired: 'Opkald udløbet - ingen deltagere',
    meeting_concluded: 'Opkald afsluttet',
    meetings_list: 'Opkalds liste',
    started_time: 'Start tidspunkt',
    duration_minutes: 'Opkaldsvarighed',
    all_hosts: 'Alle værter',
    search: 'Søg',
    from: 'Fra',
    to: 'Til',
    found_meeting_based_on_search: 'Fundet opkald baseret på søgning',
    contact_us: 'Kontakt os',
    contact_us_description:
      'Vi vil gerne høre fra dig! Skriv til os og vi lover at vende tilbage til dig så hurtigt som muligt. Dine tanker og spørgsmål er vigtige for os!',
    address: 'Adresse',
    privacy_policy: 'Fortrolighedspolitik',
    terms: 'Betingelser',
    continue: 'Fortsæt',
    launch: 'Aktiver',
    delete: 'Slet',
    not_selected: 'Ikke valgt',
    start: 'Start',
    error_occurred: 'Der er sket en fejl',
    successfully_created_member: 'Gruppemedlem blev oprettet',
    successfully_deleted_member: 'Gruppemedlem er blevet slettet',
    successfully_reset_password: 'Adgangskode nulstillet',
    successfully_updated_profile: 'Brugerprofil opdateret',
    successfully_updated_password: 'Adgangskode opdateret',
    successfully_created_customer: 'Bruger Administrator oprettet',
    successfully_deleted_customer: 'Bruger Administrator slettet',
    successfully_promoted_customer: 'Bruger Administrator forfremmet',
    successfully_demoted_subadmin:
      'Forretnings Bruger Administrator degraderet',
    successfully_created_device: 'Videolink TV oprettet',
    successfully_unassigned_device: 'Videolink TV ikke-tildelt',
    successfully_updated_device: 'Videolink TV-oplysninger er blevet opdateret',
    successfully_updated_settings: 'Indstillinger er opdateret',
    successfully_created_group: 'Gruppen er oprettet',
    successfully_deleted_group: 'Gruppen er slettet',
    successfully_updated_group: 'Gruppen er opdateret',
    successfully_removed_meeting: 'Opkald er fjernet',
    successfully_created_scheduled_meeting: 'Opkald er oprettet og planlagt',
    successfully_created_instant_meeting: 'Oprettelse af øjeblikkeligt opkald',
    successfully_edited_meeting: 'Opkald redigeret',
    successfully_updated_faq: 'Spørgsmål og svar er opdateret',
    successfully_deleted_faq: 'Spørgsmål og svar er slettet',
    successfully_uploaded_logo: 'Logo er opdateret',
    successfully_uploaded_profile_picture: 'Profilbillede er opdateret',
    successfully_created_user: 'Bruger er oprettet',
    successfully_deleted_user: 'Bruger er slettet',
    waiting: 'Venter',
    inprogress: 'i gang',
    expired: 'Udløbet',
    concluded: 'Færdig',
    member: 'Gruppemedlem',
    customer: 'Bruger Administrator',
    user: 'Bruger',
    subadmin: 'sub-administrator',
    admin2: 'Administrator',
    super_admin: 'Super-Administrator',
    no_matching_record: 'Beklager, ingen matchende forekomst fundet',
    sort: 'sorter',
    sort_for: 'Sort_for',
    next_page: 'Næste side',
    previous_page: 'Forrige side',
    rows_per_page: 'Antal rækker pr. side:',
    of: 'af',
    download_csv: 'hent CSV fil',
    print: 'Print',
    view_columns: 'Se kolonner',
    filter_table: 'Filtrer table',
    all: 'Alle',
    filters: 'Filtre',
    reset: 'Nulstil',
    show_columns: 'Vis kolonner',
    show_hide_table_columns: 'Vis eller skjul tabelkolonner',
    rows_selected: 'Valgte rækker',
    delete_selected_rows: 'Slet valgte rækker',
    created: 'Oprettet',
    destroyed: 'Slettet permanent',
    duration: 'Varighed',
    something_went_wrong: 'Noget gik galt. Prøv igen senere',
    email_password_cannot_empty: 'e-mail adgangskode skal udfyldes',
    credentials_did_not_match: 'legitimationsoplysningerne stemte ikke overens',
    profile_successfully_updated: 'profil blev opdateret',
    old_password_cannot_empty: 'Gammel adgangskode skal udfyldes',
    current_password_incorrect: 'Nuværende adganskode er forkert',
    password_length: 'Adgangskode længde',
    password_validation_failed: 'Validering af adgangskode mislykkedes',
    no_files_uploaded: 'Ingen filer uploadet',
    successfully_uploaded: 'Upload er færdig',
    roomjid_mandatory: 'opkalds ID er krævet',
    user_not_found: 'Bruger er ikke fundet',
    user_authorization_unsuccessful: 'Brugergodkendelse mislykkedes',
    deviceid_cannot_empty: 'Videolink TV ID skal udfyldes',
    user_already_assigned: 'En bruger er allerede tildelt dette Videolink TV',
    user_already_assigned2: 'En bruger er allerede tildelt dette Videolink TV',
    device_not_exists: 'En Videolink TV med dette ID fndes ikke',
    name_cannot_empty: 'navn skal udfyldes',
    group_already_exists: 'Gruppen findes i forvejen',
    user_with_userid_not_exists: 'En bruger med dette ID findes ikke',
    groupid_cannot_empty: 'gruppe ID skal udfyldes',
    members_cannot_empty: 'Gruppemedlemmer må ikke være tomme',
    group_with_groupid_not_exists: 'Gruppe med dette gruppe ID findes ikke',
    meeting_name_mandatory: 'Opkaldenavn skal udfyldes',
    end_time_cannot_before_begin_time:
      'sluttidspunktet kan ikke være før starttidspunktet',
    meeting_not_found: 'Opkald ikke fundet',
    not_authorized_delete_meeting: 'Du har ikke adgang til det slettede opkald',
    meeting_not_found_or_not_started:
      'Opkald er ikke fundet eller er ikke startet, prøv igen på et senere tidspunkt',
    settings_not_found: 'indstillinger ikke fundet',
    system_title_mandatory: 'Systemtitel skal udfyldes',
    company_name_mandatory: 'Firma navn skal udfyldes',
    meeting_end_page_text_mandatory: 'Opkald slutside tekst skal udfyldes',
    logo_mandatory: 'Logo skal udfyldes',
    title_mandatory: 'Titel skal udfyldes',
    answer_mandatory: 'Svar skal udfyldes',
    terms_mandatory: 'Betingelser skal udfyldes',
    help_mandatory: 'Hjælp er påkrævet',
    privacy_policy_mandatory: 'privatlivspolitik skal udfyldes',
    user_account_already_exists: 'Brugerkonto eksisterer i forvejen',
    user_and_password_cannot_empty: 'Bruger og adgangskode skal udfyldes',
    no_account_found: 'Konto findes ikke',
    not_authorized_change_password: 'Ikke tilladt, ændre din adgangskode',
    invalid_user_role: 'Ugyldig brugerrolle',
    userid_cannot_empty: 'Bruger-id skal udfyldes',
    cannot_delete_yourself: 'Du kan kan ikke slette dig selv',
    not_authorized_delete_user: 'Ikke tilladt, slet bruger',
    please_enter_valid_email_address: 'Indtast venligst gyldig e-mailadresse',
    please_enter_valid_contact_number:
      'Indtast venligst et gyldigt telefonnummer',
    unverified_account: 'Denne e-mail er ikke bekræftet',
    verified_account:
      'Denne e-mail afventer verificering eller nulstillning af adgangskode for at kunne fortsætte',
    confirmation_email_sent:
      'Bekræftelses e-mail fremsendt. Venligst tjek din e-mail for at kunne fortsætte',
    password_cannot_exceed: 'Adgangskoden må ikke overstige',
    passwords_not_match: 'Adgangskode er ikke korrekt',
    password_reset_successful: 'Nulstilling af adgangskode lykkedes',
    customer_not_found: 'Bruger Administrator ikke fundet',
    user_role_not_customer: 'Brugerrole er ikke Bruger Administrator',
    customer_promotion_successful: 'Bruger Administrator vellykket',
    subadmin_not_found: 'Forretnings Bruger Administrator findes ikke',
    user_role_not_subadmin:
      'Brugerolle er ikke en Forretnings Bruger Administrator',
    subadmin_demotion_successful:
      'Nedgradering af Bruger Administrator lykkedes',
    dont_have_enough_permission:
      'Du har ikke de rettigheder hertil, kontakt Bruger Administrator ',
    minutes: 'minutter',
    management: 'Administration',
    owner: 'Ejer',
    edit_member: 'Rediger gruppemedlem',
    successfully_edited_member: 'med succes redigeret gruppemedlem',
    successfully_edited_customer: 'successfuldt rettet Bruger Administrator',
    required_parmeters_cannot_empty: 'nødvendig parameter skal udfyldes',
    email_already_exists: 'e-mail findes allerede',
    view: 'Vis',
    edit: 'Ret',
    edit_admin: 'Ret Administrator',
    suspend: 'De-aktiver',
    suspend_this_user: 'Ønsker du at de-aktiver denne Bruger?',
    unsuspend: 'Re-aktiver',
    unsuspend_this_user: 'Ønsker du at re-aktiver denne Bruger?',
    successfully_suspended_user: 'Bruger de-akriveret!',
    successfully_unsuspended_user: 'Bruger re-aktiveret!',
    cannot_suspend_yourself: 'Du kan ikke de- eller re-aktiver dig selv',
    not_authorized_suspend_user:
      'Mangler tilladelse til at de-/re-aktiver Bruger',
    reports: 'Opkalds Reporter',
    edit_customer: 'Ret Bruger Administrator',
    edit_subadmin: 'Ret Forretnings Bruger Administrator',
    view_subadmin: 'Vis Forretnings Bruger Administrator',
    suspend_simple: 'De-akrivere',
    account_supended: 'Bruger konto de-aktiveret',
    unsuspend_this_customer:
      'Ønsker du at re-akrivere denne Bruger Administrator??',
    unsuspend_this_subadmin:
      'Ønsker du at re-aktivere Forretnings Bruger Administrator?',
    suspend_this_customer:
      'Ønsker du at de-akrivere denne Bruger Administrator?',
    suspend_this_subadmin:
      'Ønsker du at de-aktivere denne Forretnings Bruger Administrator?',
    successfully_suspended_customer: 'Bruger Administrator de-aktiveret!',
    successfully_unsuspended_customer: 'Bruger Administrator re-aktiveret!',
    group: 'Gruppe',
    device_is_required: 'Videolink TV er nødvendig',
    device: 'Videolink TV',
    speed_dials: 'Straks opkald',
    member_will_be_deleted: 'Gruppemedlem vil blive slettet',
    device_will_be_unassigned: 'Videolink TV vil blive frigivet',
    group_will_be_deleted: 'grupper vil blive slettet',
    customer_will_be_deleted: 'Bruger administrator vil bliev slettet',
    do_you_want_to_delete_this_subadmin:
      'Ønsker du at slette denne Forretnings Bruger Administrator?',
    revoke_system_access: 'Fjern system adgang',
    gain_system_access: 'Opnå system adgang',
    delete_customer_record: 'Slet bruger record',
    type_the_word: 'Skrive ordet',
    delete_user: 'Slet Bruger',
    revoke: 'Tilbagekald',
    are_you_sure_you_want_to_delete:
      'Er du sikker på du vil slette {name} fra Systemet? Skriv "{action}" for at bekræft',
    are_your_sure_you_want_to_revoke_unsuspend:
      'Er du sikker på du vil {action} {name} fra Systemet?. Skriv "{action}" for at bekræft',
    group_management: 'Administrer Gruppe',
    update: 'Opdater',
    add: 'Tilføj',
    group_name: 'Guppe navn',
    create_group: 'Lav Gruppe',
    deactive: 'De aktiver',
    id: 'ID',
    details: 'Detaljer',
    remove: 'Slet',
    are_you_sure_you_want_to_remove: 'Are you sure you want to remove {name}?',
    device_not_authorized_delete:
      'Tilladelse til at slette VidoLink TV ikke tilladt',
    group_not_authorized_delete: 'Tilladelse til at slette Gruppe ikke tilladt',
    group_not_authorized_update: 'Tilladelse til at ændre Gruppe ikke tilladt',
    group_memberid_cannot_empty: 'Gruppe medlems ID kan ikke være tomt',
    group_member_not_exists: 'Gruppe bruger findes ikke',
    group_member_not_authorized_delete:
      'Tilladelse til at slette gruppemedlem ikke tilladt',
    managed_by: 'Administreret af',
    join_call: 'Deltag i opkald',
    invalid_role: 'Forkert rolle',
    awaiting_user_confirmation: 'Afventer bruger Bekræftelses',
    privacy_timeout: 'er udløbet!',
    please_insert_valid_privacy_timeout:
      'Indsæt et tal på mindst 15 sekunder til Accept knap!',
    device_not_authorized_reset: 'Du har ikke tilladelse til at nulstille!',
    do_you_want_to_reset_this_device:
      'Ønsker du at nulstille dette Videolink TV?',
    privacy_enabled: 'Aktiver accept knap',
    device_with_deviceid_not_exists:
      'Et Videolink TV med dette ID findes ikke!',
    select_at_least_one_device: 'Vælg mindste 1 Videolink TVf',
    devices_count: 'Antal Videolink TV',
    device_powered_off: 'Sluk Videolink-TV enhed',
    device_rebooting: 'Genstart videolink-TV enhed',
    device_call_ongoing: 'Kald igang lige nu',
    device_ready: 'Videolink-TV klar',
    true: 'Sandt',
    false: 'Falsk',
    speed_dial: 'Hurtig kald',
    email_not_found: 'E-mail ikke fundet i system ',
    password_reset_email_sent:
      'Password nulstil e-mail er send til din email. Den vil være aktiv i de næste  60 minutter',
    invalid_data: 'Forkert dato',
    email_sent_successfully: 'E-mail sendt til bruger',
    sign_in: 'Log ind',
    sign_in_to: 'Log ind til {workspace}',
    forgot_password: 'Glemt kodeord',
    ok: 'Ok',
    forgot_password_description:
      'Skrive din e-mail så vi kan finde din konto og nulstille din kode.',
    forgot_password_email_sent: 'Email sendt for at nulstille dit kodeord.',
    forgot_password_email_sent_description:
      'Nulstillings e-mail er sendt. Kontroller din e-mail indbakke',
    welcome_to_videolink: 'Velkommen til Videolink',
    please_set_new_password: 'Angive venligste en ny kode for at fortsætte',
    proceed: 'Fortsæt',
    please_retry: 'Prøve igen',
    reset_link_expired:
      'Nulstilling af kodeord er udløbet - Start forfra med nulstilling af din kode.',
    invitation_expired: 'Invitation er udløbet - Bestil en ny invitation',
    please_insert_valid_url: 'Brug venligst en korrekt URL/Link',
    url: 'URL',
    social_media: 'Social Media',
    default_system_locale: 'Standard system sprog',
    locale_mandatory: 'Valg af Sprog skal angives',
    locale_required: 'Valg af Sprog er nødvendigt',
    send_message: 'Send Message aaa',
    write_your_message: 'Write your message in here aaa',
    enter_your_name: 'Enter your name here aaa',
    enter_your_email: 'Enter your email here aaa',
    enter_your_contact_number: 'Enter your contact number here aaa',
    please_insert_valid_message: 'Please insert valid message aaa',
    successfully_sent_contactus: 'Successfully sent message aaa',
    do_you_want_to_delete_these_groups:
      'Do you want to delete these groups? aaa',
    unauthorized: 'Unauthorized aaa',
    forbidden: 'Forbidden aaa',
    not_found: 'Not found aaa',
    select_a_group: 'Select a group aaa',
    you_have: 'You have aaa',
    today: 'today aaa',
    total_meet_hours: 'Total meet hours : aaa',
    users_in_meetings: 'users in meetings aaa',
    ongoing_meetings: 'Ongoing meetings aaa',
    recent: 'Recent aaa',
    ongoing: 'Ongoing aaa',
    upcoming: 'Upcoming aaa',
    meeting_id: 'Meeting ID aaa',
    view_details: 'View details aaa',
    live_streamings: 'Live Streamings aaa',
    participant_count: 'Participant Count aaa',
    new: 'new aaa',
    recent_livestreaming: 'Recent Streamings aaa',
    upcoming_livestreaming: 'Upcoming Streamings aaa',
    ongoing_livestreaming: 'Ongoing Streamings aaa',
    create_stream: 'Create Live Streaming aaa',
    create_stream_description:
      'Start streaming live to engage your community instantly. aaa',
    create: 'Create aaa',
    title: 'Title aaa',
    date: 'date aaa',
    delete_stream: 'Delete Live Streaming aaa',
    delete_stream_description:
      'This action cannot be undone! Are you sure you want to delete this “{name}” Stream? aaa',
    no_title: 'No title aaa',
    successfully_created_livestreaming:
      'successfully created livestreaming aaa',
    successfully_edited_livestreaming: 'successfully edited livestreaming aaa',
    successfully_deleted_livestreaming:
      'successfully deleted livestreaming aaa',
    are_you_sure: 'Are you sure? aaa',
    youtube_disconnect_description:
      'Confirm to disconnect YouTube link and stop all related streaming services. You can reconnect anytime. aaa',
    youtube_connectivity: 'Youtube Connectivity aaa',
    view_stream: 'Live Streaming Details aaa',
    update_stream: 'Update Live Streaming Details aaa',
    view_stream_description:
      'View all important details of your live streaming sessions. aaa',
    create_streaming_viewers_note:
      '"Streaming Viewers" are the actual viewers who are viewing the live streaming event. aaa',
    meeting_participants: 'Meeting Participants aaa',
    streaming_viewers: 'Streaming Viewers aaa',
    filter: 'Filter aaa',
    dense: 'Dense aaa',
    dense_padding: 'Dense padding aaa',
    select: 'Select aaa',
    streamings: 'Streamings aaa',
    scheduled_events: 'Scheduled Events aaa',
    recent_scheduled_events: 'Recent Scheduled Events aaa',
    upcoming_scheduled_events: 'Upcoming Scheduled Events aaa',
    ongoing_scheduled_events: 'Ongoing Scheduled Events aaa',
    recent_events: 'Recent Events aaa',
    upcoming_events: 'Upcoming Events aaa',
    ongoing_events: 'Ongoing Events aaa',
    create_scheduled_event: 'Create Scheduled Event aaa',
    update_scheduled_event: 'Edit Scheduled Event aaa',
    view_scheduled_event: 'View Scheduled Event aaa',
    streaming_url: 'Streaming URL aaa',
    create_scheduled_event_description:
      'Schedule an event to engage your community instantly. aaa',
    view_scheduled_event_description:
      'View all important details of your scheduled event. aaa',
    enter_valid_url: 'Please enter a valid URL. aaa',
    successfully_created_scheduled_event: 'successfully scheduled an event aaa',
    successfully_edited_scheduled_event:
      'successfully edited scheduled event aaa',
    successfully_deleted_scheduled_event:
      'successfully deleted scheduled event aaa',
    view_more: 'View More aaa',
    total_meetings: 'Total Meetings: {count} aaa',
    view_meeting: 'View Meeting aaa',
    view_meeting_description: 'View the meeting details. aaa',
    count_participants: '{count} participants aaa',
    videolink_tv_count: 'Videolink TV count aaa',
    total_speed_dials: 'Total Speed Dials : {count} aaa',
    view_speed_dial: 'View Speed Dial aaa',
    view_speed_dial_description: 'View details of your Videolink TV. aaa',
    videolink_tv: 'Videolink TV aaa',
    deleted: 'deleted aaa',
    meeting_name: 'Meeting Name aaa',
    scheduledevent_cannot_empty: 'scheduled event cannot empty aaa',
    scheduledevent_not_exists: 'scheduled event not exists aaa',
    scheduledevent_not_authorized_stop:
      'scheduled event not authorized to stop aaa',
  },
};

interface ILanguage {
  label: string;
  value: string;
}

export const getLanguageArr = (): ILanguage[] => {
  const languageArr: ILanguage[] = [];
  Object.entries(LOCALES).forEach(([label, value]) => {
    languageArr.push({
      label: label.charAt(0).toUpperCase() + label.slice(1).toLocaleLowerCase(),
      value,
    });
  });
  return languageArr;
};

import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export interface IScheduledMeetingData {
  id?: string;
  name: string;
  scheduledeventViewingUrl: string;
  groupId: string | null;
  deviceId: string | null;
  devices: string[];
  endTime: Date;
  beginTime: Date;
  participantCount: number;
  livestreamingGroupId: string | null;
  livestreamingDevices: string[];
  members: string[];
}

export const createScheduledEventApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post('meetings/createscheduledevent', data);
};

export const readScheduledEventApi = (data: any): Promise<AxiosResponse> => {
  if (data) {
    return api.post('meetings/getscheduledevents', data);
  }
  return api.post('meetings/getscheduledevents');
};

export const updateScheduledEventApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post(`meetings/updatescheduledevent/${data.id}`, data);
};

export const deleteScheduledEventApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`meetings/deletescheduledevent/${id}`);
};

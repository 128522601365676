import React, { memo, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { generateRoomWithoutSeparator } from '../../../helper/random';
import { isLowercaseAlphanumeric } from '../../../helper/validation';
import { replaceRoomNameWithTitle } from '../../../app/config';
import { IGetDeviceResponse } from '../../devices/devices.slice';
import { ICreateInstant } from '../scheduleMeeting.api';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    select: {
      width: '100%',
      color: '#989898',
      margin: '10px 0',
      '& em': {
        fontStyle: 'unset !important',
      },
    },
  });
};

export interface IInstantMeetingModalProps extends WithStyles<typeof styles> {
  open: boolean;
  devices: IGetDeviceResponse;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  startMeetingButtonClick: (data: ICreateInstant) => void;
}

const InstantMeetingModal = ({
  classes,
  open,
  devices,
  handleClose,
  startMeetingButtonClick,
}: IInstantMeetingModalProps) => {
  const [meetingName, setMeetingName] = useState({ value: '', error: '' });
  const [device, setDevice] = useState({ value: '', error: '' });
  const [location, setLocation] = useState({ value: '', error: '' });
  const intl = useIntl();

  const _handleNameChange = (e: { target: { value: string } }) => {
    if (replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
      setLocation({ value: e.target.value, error: '' });
    } else if (!replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
    }
  };

  const _handleLocationChange = (e: { target: { value: string } }) => {
    if (isLowercaseAlphanumeric(e.target.value))
      setLocation({ value: e.target.value, error: '' });
  };

  const _handleDeviceChange = (e: { target: { value: unknown } }) => {
    setDevice({ value: e.target.value as string, error: '' });
  };

  const _generateRoomName = () => {
    const roomName = generateRoomWithoutSeparator();
    setLocation({ value: roomName, error: '' });
  };

  const _validate = () => {
    let isValidate = true;
    if (!meetingName.value) {
      isValidate = false;
      setMeetingName({
        ...meetingName,
        error: intl.formatMessage({
          id: 'meeting_name_required',
        }),
      });
    }

    // if (!device.value) {
    //   isValidate = false;
    //   setDevice({
    //     ...device,
    //     error: intl.formatMessage({
    //       id: 'device_is_required',
    //     }),
    //   });
    // }

    return isValidate;
  };

  const _startMeetingButtonClick = () => {
    if (_validate()) {
      const data = {
        beginTime: new Date(),
        name: meetingName.value,
        location: location.value,
        deviceId: device.value,
      };

      startMeetingButtonClick(data);
    }
  };
  return (
    <ActionModal
      modalTitle={intl.formatMessage({
        id: 'instant_meeting',
      })}
      modalDescription={intl.formatMessage({
        id: 'instant_meeting_description',
      })}
      id="instant-meeting-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={_startMeetingButtonClick}
      actinButtonLabel={intl.formatMessage({
        id: 'start_meeting',
      })}
    >
      <TextField
        placeholder={intl.formatMessage({
          id: 'add_meeting_title',
        })}
        onChange={_handleNameChange}
        value={meetingName.value}
        required
        error={Boolean(meetingName.error)}
        helperText={meetingName.error}
      />
      {!replaceRoomNameWithTitle && (
        <TextField
          placeholder="Add room name"
          onChange={_handleLocationChange}
          value={location.value}
          error={Boolean(location.error)}
          helperText={location.error}
        />
      )}
      <FormControl className={classes.select}>
        <InputLabel id="instant-meeting-select-label">
          <FormattedMessage id="device" />
        </InputLabel>
        <Select
          value={device.value}
          onChange={_handleDeviceChange}
          labelId="instant-meeting-select-label"
          id="instant-meeting-select"
          label="Device"
          className={classes.select}
          error={Boolean(device.error)}
        >
          {devices.data.map((data) => {
            return (
              <MenuItem value={data._id} key={data._id}>
                <em>{data.deviceName}</em>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {!replaceRoomNameWithTitle && (
        <Button
          color="primary"
          id="auto generate meeting-room"
          variant="text"
          label="Or generate meeting name"
          onClick={_generateRoomName}
          // customStyles={classes.tableActionButton}
        />
      )}
    </ActionModal>
  );
};

export default memo(withStyles(styles)(InstantMeetingModal));

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError } from '../../components/types';
import { RootState } from '../../app/store';
import {
  IScheduledMeetingData,
  readScheduledEventApi,
  deleteScheduledEventApi,
  createScheduledEventApi,
  updateScheduledEventApi,
} from './scheduledEvent.api';
import { actions as appActions } from '../app/app.slice';
import {
  IScheduleMeetings,
  emptyScheduleMeeting,
} from '../scheduleMeeting/scheduleMeeting.slice';

export interface IScheduledEventsViewState {
  data: IScheduleMeetings;
  list: Array<any>;
  overview: any;
  loading: boolean;
  success: boolean;
  error: ApiError;
  isScheduleModalOpen: boolean;
  isUpdateScheduledEvent: boolean;
  isCreateScheduledEvent: boolean;
  isViewScheduledEvent: boolean;
}

const initialState: IScheduledEventsViewState = {
  list: [],
  overview: null,
  loading: false,
  success: false,
  error: null,
  data: emptyScheduleMeeting,
  isScheduleModalOpen: false,
  isUpdateScheduledEvent: false,
  isCreateScheduledEvent: false,
  isViewScheduledEvent: false,
};

export const createScheduledEventAsync = createAsyncThunk(
  'scheduledEvent/create',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await createScheduledEventApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readScheduledEventAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_created_scheduled_event',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const readScheduledEventAsync = createAsyncThunk(
  'scheduledEvent/fetch',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await readScheduledEventApi(data);
      return response.data;
    } catch (error: ApiError) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateScheduledEventAsync = createAsyncThunk(
  'scheduledEvent/update',
  async (data: IScheduledMeetingData, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateScheduledEventApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(readScheduledEventAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_edited_scheduled_event',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteScheduledEventAsync = createAsyncThunk(
  'scheduledEvent/delete',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteScheduledEventApi(data);

      // fetch all the meetings after remove a meeting
      dispatch(readScheduledEventAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully_deleted_scheduled_event',
        })
      );
      return response.data;
    } catch (error: ApiError) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'error_occurred',
        })
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const ScheduledEventsSlice = createSlice({
  name: 'scheduledEvent',
  initialState,
  reducers: {
    openScheduleModal(state) {
      state.isScheduleModalOpen = true;
    },
    closeScheduleModal(state) {
      state.isScheduleModalOpen = false;
      state.isCreateScheduledEvent = false;
      state.isViewScheduledEvent = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    openCreateScheduledEventModal(state) {
      state.isScheduleModalOpen = true;
      state.isCreateScheduledEvent = true;
    },
    openUpdateScheduledEventModal(state, action) {
      state.isUpdateScheduledEvent = true;
      state.isScheduleModalOpen = true;
      state.data = action.payload;
    },
    openViewScheduledEventModal(state, action) {
      state.isUpdateScheduledEvent = false;
      state.isScheduleModalOpen = true;
      state.isViewScheduledEvent = true;
      state.data = action.payload;
    },
    closeEditMeetingModal(state) {
      state.isScheduleModalOpen = false;
      state.isUpdateScheduledEvent = false;
      state.isViewScheduledEvent = false;
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
    resetScheduleMeeting(state) {
      state.loading = false;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch scheduledEvents
    builder.addCase(readScheduledEventAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(readScheduledEventAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data?.meetings;
      state.overview = action?.payload?.data?.overview;
    });
    builder.addCase(readScheduledEventAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // schedule a new scheduledEvent
    builder.addCase(createScheduledEventAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(createScheduledEventAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(createScheduledEventAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // delete a scheduledEvent
    builder.addCase(deleteScheduledEventAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteScheduledEventAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteScheduledEventAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // edit a scheduledEvent
    builder.addCase(updateScheduledEventAsync.pending, (state, action) => {
      state.loading = true;
      state.data = emptyScheduleMeeting;
      state.success = false;
      state.error = null;
    });
    builder.addCase(updateScheduledEventAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(updateScheduledEventAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
  },
});

export const { actions } = ScheduledEventsSlice;

export const selectScheduledEvent = (
  state: RootState
): IScheduledEventsViewState => state.scheduledEvent;

export default ScheduledEventsSlice.reducer;

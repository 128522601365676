/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import styles from './scheduledEvent.styles';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { IRequestParams } from '../../components/types';
import TabViewMeetings from './component/tabViewMeetings';

interface IScheduledEventsView extends WithStyles<typeof styles> {
  upcomingMeetings: Array<any>;
  ongoingMeetings: Array<any>;
  recentMeetings: Array<any>;
  permissions: any;
  removeMeeting: (id: string) => void;
  joinToMeetingButtonClick: (data: any) => void;
  scheduleMeetingOnClick: () => void;
  handleSearch: (data: IRequestParams) => void;
  openUpdateScheduledEventModal: (data: any) => void;
  openViewScheduledEventModal: (data: any) => void;
}

const ScheduledEventsView: React.FC<IScheduledEventsView> = ({
  classes,
  upcomingMeetings,
  ongoingMeetings,
  recentMeetings,
  permissions,
  removeMeeting,
  joinToMeetingButtonClick,
  scheduleMeetingOnClick,
  handleSearch,
  openUpdateScheduledEventModal,
  openViewScheduledEventModal,
}: IScheduledEventsView) => {
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper}>
        <Typography variant="h3">
          {intl.formatMessage({
            id: 'scheduled_events',
          })}
        </Typography>
        {permissions.addScheduledEvents ? (
          <div className={classes.topButtons}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRoundedIcon />}
              label={intl.formatMessage({
                id: 'new',
              })}
              onClick={() => scheduleMeetingOnClick()}
            />
          </div>
        ) : null}
      </div>

      <div className={classes.bottomWrapper}>
        <TabViewMeetings
          upcomingMeetings={upcomingMeetings}
          ongoingMeetings={ongoingMeetings}
          recentMeetings={recentMeetings}
          removeMeeting={removeMeeting}
          joinToMeetingButtonClick={joinToMeetingButtonClick}
          openUpdateScheduledEventModal={openUpdateScheduledEventModal}
          openViewScheduledEventModal={openViewScheduledEventModal}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(ScheduledEventsView));

/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { withStyles, WithStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Avatar,
  Grid,
  Button as MuiButton,
  Icon,
  Tooltip,
} from '@material-ui/core';
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Dvr as DvrIcon,
  AccessTime as AccessTimeIcon,
  Group as GroupIcon,
  Call as CallIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import styles from './dashboard.styles';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
// import { ScheduleMeetingButtons } from '../scheduleMeeting';
import { ShareMeetingModal } from '../meetings/components';
import { NoDataLayout } from '../../components/layout';
import {
  emptyOverviewViewIcon,
  InstantMeetingIcon,
  ScheduleMeetingIcon,
  HelpIcon,
  LargeSettingsIcon,
} from '../../assets/icons';
// import { DeviceChooser } from '../../components/DeviceChooser';
import { isSameDay } from '../../helper/calendar';
import {
  getDeviceStatus,
  getMeetingName,
} from '../../helper/translationFunctions';
import BasicButton from '../../components/Button/BasicButton';
import TabViewMeetings from './component/tabViewMeetings';
import { IRequestParams } from '../../components/types';
import { IUser } from '../auth/auth.slice';
import { IGetDeviceResponse } from '../devices/devices.slice';
import { IPermissions } from '../../app/permission';

interface IDashboardView extends WithStyles<typeof styles> {
  // upcomingMeetings: Array<any>;
  // ongoingMeetings: Array<any>;
  // recentMeetings: Array<any>;
  todayMeetings: Array<any>;
  allMeetings: Array<any>;
  overview: any;
  user: IUser;
  devices: IGetDeviceResponse;
  permissions: IPermissions;
  scheduleMeetingOnClick: () => void;
  instantMeetingOnClick: () => void;
  joinToMeetingButtonClick: (data: any) => void;
  openEditMeetingModal: (data: any) => void;
  handleSpeeddial: (data: any) => void;
  handleSpeeddialJoinCall: (id: string) => void;
  handleSearch: (data: IRequestParams) => void;
  removeMeeting: (id: string) => void;

  //
  // LiveStreaming ---------------------
  allStreamings: Array<any>;
  removeStreaming: (id: string) => void;
  joinToStreamingButtonClick: (data: any) => void;
  scheduleStreamingOnClick: () => void;
  handleSearchStreaming: (data: IRequestParams) => void;
  openUpdateLivestreamingModal: (data: any) => void;
  openViewLivestreamingModal: (data: any) => void;

  //
  // ScheduledEvents---------------------
  allScheledEvents: Array<any>;
  removeScheduledEvent: (id: string) => void;
  joinScheduledEventOnClick: (data: any) => void;
  createScheduledEventOnClick: () => void;
  handleSearchScheduledEvent: (data: IRequestParams) => void;
  openUpdateScheduledEventModal: (data: any) => void;
  openViewScheduledEventModal: (data: any) => void;
}

interface ICustomLargeButtonProps {
  id: string;
  onClick: () => void;
  icon: any;
  textId: string;
  customClass: any;
}

const DashboardView: React.FC<IDashboardView> = ({
  classes,
  // upcomingMeetings,
  // ongoingMeetings,
  // recentMeetings,
  todayMeetings,
  allMeetings,
  overview,
  user,
  permissions,
  devices,
  scheduleMeetingOnClick,
  instantMeetingOnClick,
  joinToMeetingButtonClick,
  openEditMeetingModal,
  handleSpeeddial,
  handleSpeeddialJoinCall,
  handleSearch,
  removeMeeting,

  //
  // LiveStreaming
  allStreamings,
  removeStreaming,
  joinToStreamingButtonClick,
  scheduleStreamingOnClick,
  handleSearchStreaming,
  openUpdateLivestreamingModal,
  openViewLivestreamingModal,

  //
  // ScheduledEvents
  allScheledEvents,
  removeScheduledEvent,
  joinScheduledEventOnClick,
  createScheduledEventOnClick,
  handleSearchScheduledEvent,
  openUpdateScheduledEventModal,
  openViewScheduledEventModal,
}: IDashboardView) => {
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const theme = useTheme();
  const isTabScreen = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();
  const history = useHistory();

  const _handleOpenShareMeetingModal = (data: any) => {
    setSelectedMeeting(data);
    setIsShareMeetingOpen(true);
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
  };

  const _recentMeetionActionButtonClick = (isExpired: boolean, data: any) => {
    if (isExpired) openEditMeetingModal(data);
    else joinToMeetingButtonClick(data);
  };

  const recentMtnTableColumns = [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            maxWidth: isTabScreen ? 'auto' : '120px',
            overflow: 'hidden',
          },
        }),
      },
    },
    {
      name: 'host',
      label: intl.formatMessage({
        id: 'host',
      }),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { maxWidth: isTabScreen ? 'auto' : '100px' },
        }),
        customBodyRender: (value: any) => {
          return <div>{value?.email}</div>;
        },
      },
    },
    {
      name: 'beginTime',
      label: intl.formatMessage({
        id: 'time',
      }),
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { maxWidth: isTabScreen ? 'auto' : '30px' },
        }),
        customBodyRender: (value: any) => {
          return (
            <div>
              <FormattedRelativeTime
                value={moment(value).unix() - moment.now() / 1000}
                numeric="auto"
                updateIntervalInSeconds={60}
              />
            </div>
          );
        },
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            maxWidth: isTabScreen ? 'auto' : '50px',
            overflow: 'hidden',
          },
        }),
      },
    },
  ];

  const speedDialColumns = [
    {
      name: 'deviceName',
      label: intl.formatMessage({
        id: 'name',
      }),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'rebootStatus',
      label: intl.formatMessage({
        id: 'status',
      }),
      options: {
        filter: true,
        sort: true,
        display: !isTabScreen,
      },
    },
    {
      name: 'info',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        display: !isTabScreen,
      },
    },
    {
      name: 'action',
      label: intl.formatMessage({
        id: 'action',
      }),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { maxWidth: '120px' },
        }),
      },
    },
  ];

  // const recentMtnRowEvent = useCallback(() => {
  //   return map(recentMeetings, (row) => {
  //     const isExpired = new Date() > new Date(row?.endTime);
  //     return {
  //       ...row,
  //       name: getMeetingName(intl, row),
  //       action: (
  //         <Button
  //           color={isExpired ? 'secondary' : 'primary'}
  //           id="overview-meeting-table-action"
  //           variant="text"
  //           label={
  //             !isExpired
  //               ? intl.formatMessage({
  //                   id: 'rejoin',
  //                 })
  //               : row.type === 'instant' || user?.role === 'member'
  //               ? ''
  //               : intl.formatMessage({
  //                   id: 'reschedule',
  //                 })
  //           }
  //           onClick={() => _recentMeetionActionButtonClick(isExpired, row)}
  //           customStyles={classes.rejoinButton}
  //         />
  //       ),
  //     };
  //   });
  // }, [recentMeetings]);

  const _handleSpeeddialButton = (row: any) => {
    const status = row?.rebootStatus;
    if (status === 'ready' || status === 'device_ready') {
      const data = {
        beginTime: new Date(),
        name: null,
        location: null,
        deviceId: row?._id,
      };
      handleSpeeddial(data);
    } else {
      const str1 = row?.commands?.meetingUrl;
      if (str1) {
        const str2 = str1.substring(0, str1.indexOf('?'));
        const meetingLocation = str2.substring(str2.lastIndexOf('/') + 1);

        handleSpeeddialJoinCall(meetingLocation);
      }
    }
  };
  const getButtonData = (row: any) => {
    const status = row?.rebootStatus;
    switch (status) {
      case 'device_call_ongoing':
      case 'ongoing': // Need to replace with the correct device status
        return {
          color: 'primary',
          label: intl.formatMessage({
            id: isTabScreen ? 'join' : 'join_call',
          }),
        };
      case 'device_ready':
      case 'ready':
        return {
          color: 'secondary',
          label: intl.formatMessage({
            id: isTabScreen ? 'start' : 'start_call',
          }),
        };
      default:
        return {
          disabled: true,
          label: 'Ended',
        };
    }
  };

  const speedDialRowEvent = useCallback(() => {
    return map(devices.data, (row) => {
      return {
        ...row,
        rebootStatus: getDeviceStatus(intl, row.rebootStatus),
        info: (
          <Tooltip
            arrow
            title={
              <FormattedMessage id={row.rebootStatus || 'Meeting Ended'} />
            }
          >
            <InfoOutlinedIcon className={classes.speedDialInfoIcon} />
          </Tooltip>
        ),
        action: (
          <BasicButton
            fullWidth
            id="speed-dial-table-action"
            variant="contained"
            onClick={() => _handleSpeeddialButton(row)}
            {...getButtonData(row)}
          />
        ),
      };
    });
  }, [devices]);

  const renderTodayMeetings = useCallback(() => {
    return allMeetings?.map((meeting, index) => {
      const meetingType = meeting?.meetingType || 'upcoming';
      return (
        <Card
          key={index}
          customStyles={clsx(
            classes.blueCard,
            meetingType === 'ongoing' && classes.greenCard,
            meetingType === 'past' && classes.grayCard
          )}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1" component="p">
                {meeting?.name}
              </Typography>
              <div className={classes.blueCardMeetingDetails}>
                <div className={classes.blueCardMeetingStatsItem}>
                  <AccessTimeIcon
                    className={classes.blueCardMeetingStatsIcon}
                  />
                  <Typography variant="body2" component="p">
                    {moment(meeting?.beginTime).format('YYYY/MM/DD hh:mm A')}
                    {meeting?.endTime &&
                      ` - ${moment(meeting?.endTime).format(
                        isSameDay(meeting?.beginTime, meeting?.endTime)
                          ? 'hh:mm A'
                          : 'YYYY/MM/DD hh:mm A'
                      )}`}
                  </Typography>
                </div>
                <div className={classes.blueCardMeetingStatsItem}>
                  <DvrIcon className={classes.blueCardMeetingStatsIcon} />
                  <Typography variant="body2" component="p">
                    <FormattedMessage id="room" /> : {meeting?.location}
                  </Typography>
                </div>
                <div className={classes.blueCardMeetingStatsItem}>
                  <AccountCircleOutlinedIcon
                    className={classes.blueCardMeetingStatsIcon}
                  />
                  <Typography variant="body2" component="p">
                    <FormattedMessage id="host" /> : {meeting?.host?.email}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.blueCardRighWrapper}>
              {meetingType !== 'past' && (
                <div className={classes.blueCardButtonWrapper}>
                  <Button
                    id="blue-card-launch-button"
                    label={intl.formatMessage({
                      id: 'launch',
                    })}
                    variant="contained"
                    customStyles={classes.blueCardButton}
                    onClick={() => joinToMeetingButtonClick(meeting)}
                  />
                  <Button
                    id="blue-card-share-button"
                    label={intl.formatMessage({
                      id: 'share_invitation',
                    })}
                    variant="contained"
                    customStyles={classes.blueCardButton}
                    onClick={() => _handleOpenShareMeetingModal(meeting)}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Card>
      );
    });
  }, [allMeetings]);

  // if (recentMeetings.length === 0 && todayMeetings.length === 0) {
  //   return (
  //     <NoDataLayout
  //       image={emptyOverviewViewIcon}
  //       heading={intl.formatMessage({
  //         id: 'virtual_meetings_for_everyone',
  //       })}
  //       description={intl.formatMessage({
  //         id: 'dashboard_description',
  //       })}
  //     >
  //       <ScheduleMeetingButtons />
  //     </NoDataLayout>
  //   );
  // }
  const filterFirstName = (fullName: string) => {
    const firstName = fullName?.split(' ')[0];
    return firstName;
  };

  const profileCard = (
    <Card customStyles={classes.profileCard}>
      <div className={classes.profilePicWrapper}>
        <Avatar
          className={classes.avatar}
          alt={user?.profile?.name || 'User'}
          src={user?.profile?.picture || '/static/images/avatar/1.jpg'}
        />
      </div>
      <div className={classes.profileNameWrapper}>
        <Typography
          variant="subtitle1"
          component="h3"
          className={classes.profileName}
        >
          <span className={classes.primaryColorText}>
            <FormattedMessage id="hello" />{' '}
          </span>
          {filterFirstName(user?.profile?.name)}!
        </Typography>
        <Typography
          variant="h2"
          component="h1"
          className={classes.profileTodayMeeting}
        >
          <FormattedMessage id="you_have" />
          <span className={classes.primaryColorText}>
            {' '}
            {todayMeetings?.length} <FormattedMessage id="meetings" />{' '}
          </span>
          <FormattedMessage id="today" />
        </Typography>
      </div>
      {/* <div className={classes.profileStats}>
        <div
          className={clsx(classes.profileStatsList, classes.profileStatmargin)}
        >
          <CallIcon />
          <Typography variant="subtitle2" component="p">
            <FormattedMessage id="total_meet_hours" />{' '}
            <span className={classes.primaryColorText}>
              {Math.round(overview?.totalTime / (60 * 60 * 1000)) || 0}h
            </span>
          </Typography>
        </div>
        <div className={classes.profileStatsList}>
          <GroupIcon />
          <Typography variant="subtitle2" component="p">
            {overview?.totalParticipants || 0}{' '}
            <FormattedMessage id="users_in_meetings" />
          </Typography>
        </div>
      </div> */}
    </Card>
  );

  const CustomLargeButton = ({
    id,
    onClick,
    icon,
    textId,
    customClass,
  }: ICustomLargeButtonProps) => {
    return (
      <MuiButton
        id={`${id}-button`}
        disableTouchRipple
        onClick={onClick}
        className={classes.customLargeButton}
      >
        <Icon className={clsx(classes.customLargeButtonIcon, customClass)}>
          <img src={icon} alt={`${id}-icon`} />
        </Icon>
        <Typography
          variant="body1"
          className={classes.customLargeButtonText}
          component="p"
        >
          <FormattedMessage id={textId} />
        </Typography>
      </MuiButton>
    );
  };

  const buttonsCard = (
    <Card customStyles={classes.buttonWrapper}>
      {permissions.instantMeeting ? (
        <CustomLargeButton
          id="instant-meeting-dashboard"
          onClick={instantMeetingOnClick}
          icon={InstantMeetingIcon}
          textId="start_call"
          customClass={classes.customLargeButtonPrimary}
        />
      ) : null}
      {permissions.scheduleMeeting ? (
        <CustomLargeButton
          id="schedule-meeting-dashboard"
          onClick={scheduleMeetingOnClick}
          icon={ScheduleMeetingIcon}
          textId="schedule"
          customClass={classes.customLargeButtonSecondary}
        />
      ) : null}
      <CustomLargeButton
        id="help-dashboard"
        onClick={() => history.push('/help')}
        icon={HelpIcon}
        textId="help"
        customClass={classes.customLargeButtonBlue}
      />
      <CustomLargeButton
        id="help-dashboard"
        onClick={() => history.push('/settings')}
        icon={LargeSettingsIcon}
        textId="settings"
        customClass={classes.customLargeButtonRed}
      />
    </Card>
  );

  const speedDialCard = (
    <Card customStyles={classes.speedDialWrapper}>
      <Table
        id="speed-dial-table"
        title={intl.formatMessage({
          id: 'speed_dials',
        })}
        customStyles={classes.speedDialTable}
        columns={speedDialColumns}
        data={speedDialRowEvent()}
        pagination={false}
        denseControl={false}
      />
    </Card>
  );

  // const upcomingMeetingsCard = (
  //   <div className={classes.leftBottom}>
  //     {allMeetings.length > 0 ? (
  //       <div className={classes.scrollableContainer}>
  //         {renderTodayMeetings()}
  //       </div>
  //     ) : (
  //       <NoDataLayout
  //         image={emptyOverviewViewIcon}
  //         headingTypeTwo={intl.formatMessage({
  //           id: 'no_upcoming_meeting',
  //         })}
  //         customStyles={classes.noUpComming}
  //       />
  //     )}
  //   </div>
  // );

  // const recentMeetingsCard = (
  //   <div className={classes.leftBottomRighWrapper}>
  //     <Card customStyles={classes.recentMeetingsWrapper} id="recent-meetings">
  //       <Table
  //         id="recent-meetings-table"
  //         title={intl.formatMessage({
  //           id: 'recent_meetings',
  //         })}
  //         customStyles={classes.recentMeetingsTable}
  //         columns={recentMtnTableColumns}
  //         data={recentMtnRowEvent()}
  //         pagination={false}
  //       />
  //     </Card>
  //   </div>
  // );

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={isTabScreen ? 2 : 4}
        className={classes.mainWrapper}
      >
        <Grid item xs={12} md={6} className={classes.leftWrapper}>
          <>{profileCard}</>

          <Grid container className={classes.leftBottommMain}>
            <Grid item xs={12} sm="auto">
              {buttonsCard}
            </Grid>
            <Grid item xs={12} sm="auto" style={{ flexGrow: 1 }}>
              {speedDialCard}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className={classes.tabViewWrapper}>
          <Card customStyles={classes.tabView}>
            <TabViewMeetings
              allMeetings={allMeetings}
              scheduleMeetingOnClick={scheduleMeetingOnClick}
              joinToMeetingButtonClick={joinToMeetingButtonClick}
              openEditMeetingModal={openEditMeetingModal}
              handleSearch={handleSearch}
              removeMeeting={removeMeeting}
              // upcomingMeetings={upcomingMeetings}
              // ongoingMeetings={ongoingMeetings}
              // recentMeetings={recentMeetings}
              //
              // LiveStreaming
              allStreamings={allStreamings}
              removeStreaming={removeStreaming}
              joinToStreamingButtonClick={joinToStreamingButtonClick}
              scheduleStreamingOnClick={scheduleStreamingOnClick}
              handleSearchStreaming={handleSearchStreaming}
              openUpdateLivestreamingModal={openUpdateLivestreamingModal}
              openViewLivestreamingModal={openViewLivestreamingModal}
              //
              // ScheduledEvents
              allScheledEvents={allScheledEvents}
              removeScheduledEvent={removeScheduledEvent}
              joinScheduledEventOnClick={joinScheduledEventOnClick}
              createScheduledEventOnClick={createScheduledEventOnClick}
              handleSearchScheduledEvent={handleSearchScheduledEvent}
              openUpdateScheduledEventModal={openUpdateScheduledEventModal}
              openViewScheduledEventModal={openViewScheduledEventModal}
            />
          </Card>
        </Grid>

        {isShareMeetingOpen && (
          <ShareMeetingModal
            open={isShareMeetingOpen}
            handleClose={_handleCloseShareMeetingModal}
            meeting={selectedMeeting}
          />
        )}
      </Grid>
    </div>
  );
};

export default memo(withStyles(styles)(DashboardView));

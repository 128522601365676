/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import styles from './livestreaming.styles';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import TabViewMeetings from './component/tabViewMeetings';
import { ActionModal } from '../../components/Modal';
import { Switch } from '../../components/Switch';
import { IRequestParams } from '../../components/types';

interface ILivestreamingView extends WithStyles<typeof styles> {
  upcomingMeetings: Array<any>;
  ongoingMeetings: Array<any>;
  recentMeetings: Array<any>;
  permissions: any;
  selectStream: any;
  connectYoutube: () => void;
  disconnectYoutube: () => void;
  removeMeeting: (id: string) => void;
  joinToMeetingButtonClick: (data: any) => void;
  scheduleMeetingOnClick: () => void;
  handleSearch: (data: IRequestParams) => void;
  openUpdateLivestreamingModal: (data: any) => void;
  openViewLivestreamingModal: (data: any) => void;
}

const LivestreamingView: React.FC<ILivestreamingView> = ({
  classes,
  selectStream,
  upcomingMeetings,
  ongoingMeetings,
  recentMeetings,
  permissions,
  connectYoutube,
  disconnectYoutube,
  removeMeeting,
  joinToMeetingButtonClick,
  scheduleMeetingOnClick,
  handleSearch,
  openUpdateLivestreamingModal,
  openViewLivestreamingModal,
}: ILivestreamingView) => {
  const intl = useIntl();

  const [openYoutubeConfirmModal, setOpenYoutubeConfirmModal] = useState(false);

  useEffect(() => {
    if (!selectStream.youtubeStatus) {
      setOpenYoutubeConfirmModal(false);
    }
  }, [selectStream.youtubeStatus]);

  const handleConnectDisconnectYoutube = () => {
    if (selectStream.youtubeStatus) {
      setOpenYoutubeConfirmModal(true);
    } else {
      connectYoutube();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper}>
        <Typography variant="h3">
          {intl.formatMessage({
            id: 'live_streamings',
          })}
        </Typography>
        {permissions.addLivestreamings ? (
          <div className={classes.topButtons}>
            <div className={classes.youtubeConnect}>
              <Switch
                checked={selectStream.youtubeStatus}
                onChange={handleConnectDisconnectYoutube}
                disabled={selectStream.loading}
              />
              <Typography variant="button">
                {intl.formatMessage({
                  id: 'youtube_connectivity',
                })}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRoundedIcon />}
              label={intl.formatMessage({
                id: 'new',
              })}
              onClick={() => scheduleMeetingOnClick()}
              disabled={!selectStream.youtubeStatus}
            />
          </div>
        ) : null}
      </div>

      <div className={classes.bottomWrapper}>
        <TabViewMeetings
          upcomingMeetings={upcomingMeetings}
          ongoingMeetings={ongoingMeetings}
          recentMeetings={recentMeetings}
          removeMeeting={removeMeeting}
          joinToMeetingButtonClick={joinToMeetingButtonClick}
          openUpdateLivestreamingModal={openUpdateLivestreamingModal}
          openViewLivestreamingModal={openViewLivestreamingModal}
          handleSearch={handleSearch}
        />
      </div>
      <ActionModal
        modalTitle={intl.formatMessage({
          id: 'are_you_sure',
        })}
        modalDescription={intl.formatMessage({
          id: 'youtube_disconnect_description',
        })}
        open={openYoutubeConfirmModal}
        handleClose={() => setOpenYoutubeConfirmModal(false)}
        actinButtonOnClick={disconnectYoutube}
        actinButtonLabel={intl.formatMessage({
          id: 'proceed',
        })}
      />
    </div>
  );
};

export default memo(withStyles(styles)(LivestreamingView));

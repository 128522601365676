import { AxiosResponse } from 'axios';
import api from '../../app/axios';

export interface IScheduledMeetingData {
  id?: string;
  name: string;
  location: string;
  groupId: string | null;
  deviceId: string | null;
  members: string[];
  devices: string[];
  endTime: Date;
  beginTime: Date;
  participantCount: number;
  livestreamingGroupId: string | null;
  livestreamingDevices: string[];
}

export const createLivestreamingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post('meetings/createlivestreaming', data);
};

export const readLivestreamingsApi = (data: any): Promise<AxiosResponse> => {
  if (data) {
    return api.post('meetings/getlivestreamings', data);
  }
  return api.post('meetings/getlivestreamings');
};

export const updateLivestreamingApi = (
  data: IScheduledMeetingData
): Promise<AxiosResponse> => {
  return api.post(`meetings/updatelivestreaming/${data.id}`, data);
};

export const deleteLivestreamingsApi = (id: string): Promise<AxiosResponse> => {
  return api.delete(`meetings/deletelivestreaming/${id}`);
};

export const connectYoutubeApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/connectyoutube');
};

export const disconnectYoutubeApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/disconnectyoutube');
};

export const getYoutubeStatusApi = (): Promise<AxiosResponse> => {
  return api.get('livestreaming/getyoutubestatus');
};

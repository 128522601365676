import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Theme,
  ModalProps,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MaxWidth } from '../types';
import { TextField } from '../TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 504,
      },
      '& .MuiFormHelperText-contained': {
        position: 'unset !important',
        bottom: -23,
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(4),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(3),
      },
    },
    title: {
      fontFamily: 'PT Sans',
      fontWeight: 700,
    },
    symbol: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    selectedItem: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: '12px',
      top: '12px',
      '& .MuiSvgIcon-root': {
        fill: theme.palette.secondary.contrastText,
      },
    },
    description: {
      margin: theme.spacing(0, 4, 2.5),
      color: theme.palette.secondary.contrastText,
    },
    dialogContent: {
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 3),
      },
    },
    dialogActions: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
      },
    },
  });
};

export interface IModalProps extends WithStyles<DialogProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  modalTitle?: string;
  selectedItem?: string;
  modalDescription?: string;
  actionButtons?: ReactNode;
  fullWidth?: boolean;
  maxWidth?: MaxWidth;
  disableBackdropClick?: boolean;
  customStyles?: any;
}

export const BasicModal: React.FC<IModalProps> = ({
  classes,
  id,
  open,
  modalTitle,
  selectedItem,
  modalDescription,
  children,
  actionButtons,
  handleClose,
  fullWidth = true,
  maxWidth = 'sm',
  disableBackdropClick = false,
  customStyles,
}: IModalProps) => {
  return (
    <Dialog
      id={id}
      onClose={handleClose}
      open={open}
      className={clsx(classes.root, customStyles)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
    >
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} variant="caption">
          {modalTitle}
        </Typography>
        {selectedItem && (
          <Typography className={classes.symbol} variant="caption">
            {'>'}
          </Typography>
        )}
        <Typography
          className={classes.selectedItem}
          variant="body1"
          color="primary"
        >
          {selectedItem}
        </Typography>
        <IconButton
          id="modal-close-button"
          size="small"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      {Boolean(modalDescription) && (
        <Typography className={classes.description} variant="body2">
          {modalDescription}
        </Typography>
      )}
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      {actionButtons && (
        <DialogActions className={classes.dialogActions}>
          {actionButtons}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(BasicModal);
